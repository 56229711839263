import {createAction, props} from '@ngrx/store';

export interface ActiveLanguageChangedActionPayload {
  langCode2: string;
}

export const activeLanguageChangedAction = createAction(
  '[LANG] CHANGE',
  props<ActiveLanguageChangedActionPayload>()
);

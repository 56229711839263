import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import ProductQuantityDef = Orderly.Shared.Api.Messages.ProductQuantityDef;

@Pipe({
        name: 'weight',
        pure: false,
      })
export class WeightPipe implements PipeTransform {


  constructor(private readonly translateService: TranslateService) {
  }


  transform(value: ProductQuantityDef): string {

    const aliasTranslation = this.translateService.instant(value.measureUnit.alias);

    return `${value.amount} ${aliasTranslation}`;
  }
}

<div class="categories">

  <div *ngFor="let item of categories$ | async; trackBy: categoryTrackBy"
       class="categories__item"
       [class.name-only]="item.displayType === 0 || item.thumb == null"
       (click)="navigateToDetails(item)">

    <div class="categories__img"
         *ngIf="item.thumb != null">

      <img [src]="item.thumb.url" [alt]="item.name">
    </div>

    <h3 class="categories__title">
      {{item.name}}
    </h3>

  </div>

</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'orderly-kiosk-main-shell',
  templateUrl: './main-shell.component.html',
  styleUrls: ['./main-shell.component.scss']
})
export class MainShellComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

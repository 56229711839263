import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {OrderableMenuItemModel, selectMenuFeatureMenu} from '@orderly/shared-menu-store';
import {combineLatest, filter, map, Observable, startWith} from 'rxjs';
import {MenuCategoryModel} from './model/menu-category.model';
import MenuItemGroupsListStyleDef = Orderly.Common.Enums.MenuItemGroupsListStyleDef;
import RestaurantMenu = Orderly.RestaurantWeb.Api.Messages.Public.Models.RestaurantMenu;

@Component({
             selector: 'orderly-categories-list',
             templateUrl: './categories-list.component.html',
             styleUrls: ['./categories-list.component.scss'],
             changeDetection: ChangeDetectionStrategy.OnPush,
           })
export class CategoriesListComponent {

  public readonly categories$: Observable<MenuCategoryModel[]>;

  constructor(store: Store<any>,
              translateService: TranslateService,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute) {

    const menu$: Observable<RestaurantMenu> = store.select(selectMenuFeatureMenu)
                                                   .pipe(
                                                     filter(x => x != null),
                                                     map(x => x!),
                                                   );

    const onLangCodeChange$ = translateService.onLangChange
                                              .pipe(
                                                map(x => x.lang),
                                                startWith(translateService.currentLang),
                                              );

    this.categories$ = combineLatest([onLangCodeChange$, menu$])
      .pipe(
        map((x: [string, RestaurantMenu]) => {
          const langCode2 = x[0];
          const menu = x[1];
          const sortedCategories = [...menu.categories].sort((a, b) => a.displaySortOrder - b.displaySortOrder);

          return sortedCategories.map(c => {
            return MenuCategoryModel.from(langCode2, c, menu.categoriesDisplayType);
          });
        })
      );
  }

  public navigateToDetails(item: MenuCategoryModel): void {
    this.router.navigate(['../category/' + item.externalId], {relativeTo: this.activatedRoute});
  }

  public categoryTrackBy(index: number, item: MenuCategoryModel): string {
    return item.externalId;
  }
}

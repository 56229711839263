import {Component, OnInit} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ModalProgressService} from '@orderly/shared';

@Component({
  selector: 'orderly-modal-progress-indicator',
  templateUrl: './modal-progress-indicator.component.html',
  styleUrls: ['./modal-progress-indicator.component.scss']
})
export class ModalProgressIndicatorComponent implements OnInit {

  public showProgress$: Observable<boolean> = EMPTY;

  constructor(private readonly progressModalService: ModalProgressService) { }

  ngOnInit(): void {
    this.showProgress$ = this.progressModalService.isVisible$;
  }

}

import {createFeatureSelector, createSelector} from '@ngrx/store';
import {MenuAppState} from '../models/menu-app-state';
import {TableState} from '../models/table-state';
import {menuFeatureName} from '../store-constants';

export const selectMenuFeature = createFeatureSelector<MenuAppState>(menuFeatureName);
export const selectMenuFeatureTable = createSelector(selectMenuFeature, (state: MenuAppState) => state.table);
export const selectMenuFeatureMenu = createSelector(selectMenuFeature, (state: MenuAppState) => state.table.menu);
export const selectMenuFeatureCart = createSelector(selectMenuFeature, (state: MenuAppState) => state.table.cart);
export const selectMenuFeatureAppSettings = createSelector(selectMenuFeature, (state: MenuAppState) => state.appSettings);
export const selectMenuFeatureClientFingerprint = createSelector(selectMenuFeature, (state: MenuAppState) => state.clientFingerprint);
export const selectOrdersEnabled = createSelector(selectMenuFeatureTable, (state: TableState) => {
  if (state.tokenAndActions == null) {
    return false;
  }

  return state.tokenAndActions.ordersEnabled;
});


export const selectOrderedItems = createSelector(selectMenuFeatureTable, (state: TableState) => state.cart.orderedItems);

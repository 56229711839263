export type ModifierGroupSelectionMode = 'exactly-one' | 'at-least-one' | 'zero-or-one' | 'zero-or-more';


export function calcSelectionMode(minAllowedAmount: number, maxAllowedAmount: number | null) {

  if (minAllowedAmount === 0) {
    return maxAllowedAmount == null
           ? 'zero-or-more'
           : maxAllowedAmount === 1
             ? 'zero-or-one'
             : 'zero-or-more';
  }

  if (minAllowedAmount >= 1) {
    return maxAllowedAmount == null
           ? 'at-least-one'
           : maxAllowedAmount === 1
             ? 'exactly-one'
             : 'at-least-one';
  }

  throw new Error(`Unknown selection mode. min=${minAllowedAmount}. max=${maxAllowedAmount}`);
}

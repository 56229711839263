import {NeverError} from '@orderly/shared';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {OrderableMenuItemModifierModel} from './orderable-menu-item-modifier.model';
import {calcSelectionMode, ModifierGroupSelectionMode} from './modifier-group-selection-mode';
import RestaurantMenuItemModifierGroupTranslationDto = Orderly.RestaurantWeb.DataTransferObjects.Translations.RestaurantMenuItemModifierGroupTranslationDto;
import MenuItemModifierGroup = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemModifierGroup;
import Currency = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Currency;

export class OrderableMenuItemModifierGroupModel {

  private readonly _activeLangCode2: string;

  private readonly _activeTranslation: RestaurantMenuItemModifierGroupTranslationDto | null;


  public readonly uniqueIdLowercase: string;

  public get totalPrice(): number {
    return this.orderedModifiers.reduce((prev: number, curr: OrderableMenuItemModifierModel): number => {
      return prev + curr.priceDiff.value;
    }, 0);
  }

  public readonly isValid: boolean;

  public get name(): string {
    return this._activeTranslation?.name || this.defaultName;
  }

  private _orderedModifiers: OrderableMenuItemModifierModel[] = [];

  public get orderedModifiers(): OrderableMenuItemModifierModel[] {
    return this._orderedModifiers;
  }

  public readonly selectionMode: ModifierGroupSelectionMode;


  private constructor(activeLangCode2: string,
                      uniqueId: string,
                      public readonly defaultName: string,
                      public readonly minAllowedAmount: number,
                      public readonly maxAllowedAmount: number | null,
                      orderedModifiers: OrderableMenuItemModifierModel[],
                      private readonly translations: ReadonlyArray<RestaurantMenuItemModifierGroupTranslationDto>) {

    this.uniqueIdLowercase = uniqueId.toLowerCase();

    this._activeLangCode2 = activeLangCode2;
    this._orderedModifiers = orderedModifiers;
    this._activeTranslation = OrderableMenuItemModifierGroupModel.getActiveTranslation(activeLangCode2, translations);

    this.selectionMode = calcSelectionMode(minAllowedAmount, maxAllowedAmount);

    switch (this.selectionMode) {
      case 'exactly-one':
        this.isValid = this.orderedModifiers.length === 1;
        break;
      case 'zero-or-one':
        this.isValid = this.orderedModifiers.length <= 1;
        break;
      case 'at-least-one':
        this.isValid = this.orderedModifiers.length >= 1 &&
                       (maxAllowedAmount == null || this.orderedModifiers.length <= maxAllowedAmount);
        break;
      case 'zero-or-more':
        this.isValid = maxAllowedAmount == null || this.orderedModifiers.length <= maxAllowedAmount;
        break;
      default:
        throw new NeverError(this.selectionMode);
    }
  }

  private static getActiveTranslation(langCode2: string,
                                      translations: ReadonlyArray<RestaurantMenuItemModifierGroupTranslationDto>): RestaurantMenuItemModifierGroupTranslationDto | null {

    const activeTranslationIdx = translations.findIndex(x => x.languageCode2.toLowerCase() == langCode2.toLowerCase());
    const activeTranslation = activeTranslationIdx < 0 ? null : translations[activeTranslationIdx];

    return activeTranslation;
  }

  public static from(activeLangCode2: string, source: MenuItemModifierGroup, currency: Currency): OrderableMenuItemModifierGroupModel {

    const modifiers = source.modifiers.map(x => OrderableMenuItemModifierModel.from(activeLangCode2, x, currency));

    return new OrderableMenuItemModifierGroupModel(activeLangCode2,
                                                   source.uniqueId,
                                                   source.name,
                                                   source.minAllowedAmount,
                                                   source.maxAllowedAmount,
                                                   modifiers,
                                                   source.translations);
  }

  public cloneAndChangeActiveLanguage(langCode2: string): OrderableMenuItemModifierGroupModel {

    const result = new OrderableMenuItemModifierGroupModel(langCode2,
                                                           this.uniqueIdLowercase,
                                                           this.defaultName,
                                                           this.minAllowedAmount,
                                                           this.maxAllowedAmount,
                                                           [],
                                                           this.translations);

    result._orderedModifiers = this._orderedModifiers.map((mo) => mo.cloneAndChangeActiveLanguage(langCode2));

    return result;
  }

  public cloneWithReplacedModifiers(newModifiers: OrderableMenuItemModifierModel[]): OrderableMenuItemModifierGroupModel {
    const result = new OrderableMenuItemModifierGroupModel(this._activeLangCode2,
                                                           this.uniqueIdLowercase,
                                                           this.defaultName,
                                                           this.minAllowedAmount,
                                                           this.maxAllowedAmount,
                                                           newModifiers,
                                                           this.translations);

    return result;
  }
}

import {Component, HostBinding} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AppSettingsState, selectMenuFeatureAppSettings} from '@orderly/shared-menu-store';
import {DialogService} from 'primeng/dynamicdialog';
import {map, Observable, startWith} from 'rxjs';
import {LanguagesListComponent} from '../../../modals/languages-list/languages-list.component';

@Component({
             selector: 'orderly-kiosk-language-selector',
             templateUrl: './language-selector.component.html',
             styleUrls: ['./language-selector.component.scss']
           })
export class LanguageSelectorComponent {

  @HostBinding('class.f32')
  public rootClass = true;

  public isLanguageSelectable$: Observable<boolean>;

  public iconClasses$: Observable<string>;


  constructor(private readonly dialogService: DialogService,
              private readonly store: Store<any>,
              private readonly translateService: TranslateService) {

    const currentLangCode2$ = translateService.onLangChange
                                              .pipe(
                                                map(x => x.lang),
                                                startWith(translateService.currentLang),
                                              );

    this.iconClasses$ = currentLangCode2$.pipe(
      map(code2 => {
        return `flag ${code2.toLowerCase()}`;
      }),
    );

    this.isLanguageSelectable$ = store.select(selectMenuFeatureAppSettings)
                                      .pipe(
                                        map((x: AppSettingsState) => {
                                          return x.selectableLanguages.length > 1;
                                        }),
                                      );

  }

  openModal() {
    this.dialogService
        .open(LanguagesListComponent,
              {
                closable: true,
                width: '30%',
                showHeader: false,
                dismissableMask: true,
              });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ModalProgressIndicatorComponent
} from './components/modal-progress-indicator/modal-progress-indicator.component';
import {BlockUIModule} from 'primeng/blockui';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
            imports: [
              CommonModule,
              BlockUIModule,
              ProgressSpinnerModule
            ],
            declarations: [
              ModalProgressIndicatorComponent
            ],
            exports: [
              ModalProgressIndicatorComponent
            ],
          })
export class SharedComponentsModule {}

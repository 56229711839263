import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PricePipe} from './pipes/price.pipe';
import {WeightPipe} from './pipes/weight.pipe';

@NgModule({
            imports: [
              CommonModule
            ],
            declarations: [
              PricePipe,
              WeightPipe,
            ],
            exports: [
              PricePipe,
              WeightPipe,
            ]
          })
export class SharedModule {}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {markCurrentOrderAsCompletedAction, requestNavigateToCartAction, requestNavigateToMenuAction} from '@orderly/shared-menu-store';
import {tap} from 'rxjs';
import {cartRoutePath, menuRoutePath} from '../../app-routing-constants';

@Injectable()
export class NavigationEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
  ) {
  }

  navigateToMenuAfterOrderCompleted$ = createEffect(() => this.actions$.pipe(
                                                      ofType(markCurrentOrderAsCompletedAction.type),
                                                      tap(() => {

                                                        this.router.navigate([`/${menuRoutePath}`]);
                                                      }),
                                                    ), {dispatch: false}
  );

  navigateToMenu$ = createEffect(() => this.actions$.pipe(
                                   ofType(requestNavigateToMenuAction.type),
                                   tap(() => {

                                     this.router.navigate([`/${menuRoutePath}`]);
                                   }),
                                 ), {dispatch: false}
  );

  navigateToCart$ = createEffect(() => this.actions$.pipe(
                                   ofType(requestNavigateToCartAction.type),
                                   tap(() => {

                                     this.router.navigate([`/${cartRoutePath}`]);
                                   }),
                                 ), {dispatch: false}
  );

}

import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {AppSettings, AppSettingsService, BLOCK_WINDOW_CONTEXT_KEY} from '@orderly/shared';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {BehaviorSubject, interval, map, Observable, of, switchMap, tap} from 'rxjs';
import {environment} from '../../environments/environment';
import GetWebMenuAppStartDataResponse = Orderly.RestaurantWeb.Api.Messages.Public.GetWebMenuAppStartDataResponse;
import GetWebMenuAppStartDataResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.Public.GetWebMenuAppStartDataResponse.StatusDef;


@Injectable({
              providedIn: 'root'
            })
export class InMemoryAppSettingsService extends AppSettingsService implements OnDestroy {

  private readonly cache$ = new BehaviorSubject<AppSettings | null>(null);

  constructor(private readonly httpClient: HttpClient) {
    super();

    // fetch every two hours
    interval(2 * 3600000)
      .pipe(
        switchMap(() => this.fetchDataFromServer('FROM_OTHER'))
      )
      .subscribe(this.cache$);
  }

  ngOnDestroy(): void {
    this.cache$.complete();
  }

  isDevEnvironment(): boolean {
    return !environment.production && !environment.test;
  }

  getAppSettings(callSource: 'FROM_APP_INITIALIZER' | 'FROM_OTHER'): Observable<AppSettings> {

    if (this.cache$.value == null) {
      return this.fetchDataFromServer(callSource)
                 .pipe(
                   tap(x => {
                     this.cache$.next(x);
                   })
                 );
    }

    return of(this.cache$.value);
  }

  private fetchDataFromServer(callSource: 'FROM_APP_INITIALIZER' | 'FROM_OTHER') {
    const url = `${environment.baseApiUrlWithTrailingSlash}v1.0/web-menu-app/start-data`;
    const context = callSource === 'FROM_APP_INITIALIZER'
                    ? new HttpContext().set(BLOCK_WINDOW_CONTEXT_KEY, false)
                    : new HttpContext().set(BLOCK_WINDOW_CONTEXT_KEY, true);

    return this.httpClient
               .get<GetWebMenuAppStartDataResponse>(url, {context})
               .pipe(
                 map((response) => {

                   switch (response.status) {
                     case GetWebMenuAppStartDataResponseStatusDef.Success:

                       return {
                         uiLanguages: response.appSupportedLanguages,
                         loadedOnUnixTime: new Date().getTime(),
                       };

                     default:
                       throw new Error('Failed to get menu app start data');
                   }

                 }),
               );
  }
}

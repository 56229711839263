<div class="ingredients">
  <div class="column column-left">
    <div class="ingredients-img"
         *ngIf="selectedMenuItem.thumb != null">

      <img [src]="selectedMenuItem.thumb.url"
           [alt]="selectedMenuItem.thumb.name"
           [height]="selectedMenuItem.thumb.height.toString()"
           [width]="selectedMenuItem.thumb.width.toString()">

    </div>

    <p class="dish__weight" *ngIf="selectedMenuItem.quantity != null && selectedMenuItem.sizes.length === 0">
      <span class="label">{{'Weight' | translate}}: </span>
      <span class="value">{{selectedMenuItem.quantity | weight}}</span>
    </p>

    <p class="dish__description"
       *ngIf="selectedMenuItem.description != null && selectedMenuItem.description !== ''">

      {{selectedMenuItem.description}}
    </p>

    <p class="dish__ingredients"
       *ngIf="selectedMenuItem.ingredientsListAsText.length > 0">

      {{selectedMenuItem.ingredientsListAsText}}
    </p>

    <orderly-item-quantity-input *ngIf="isReadonly === false"
                                 [quantity]="selectedMenuItem.orderedMenuItemCount"
                                 (selectionChange)="selectedMenuItem.changedOrderedMenuItemCount($event)">
    </orderly-item-quantity-input>
  </div>
  <div class="column column-right">
    <div>
      <div class="sizes" *ngIf="selectedMenuItem.sizes.length > 0">
        <orderly-item-sizes-selector [readonly]="isReadonly"
                                     [currencyCode3]="selectedMenuItem.price.currency.code3"
                                     (sizeChanged)="selectedMenuItem.selectSize($event)"
                                     [model]="selectedMenuItem.orderedSize"
                                     [sizes]="selectedMenuItem.sizes">
        </orderly-item-sizes-selector>
      </div>


      <div class="options" *ngIf="selectedMenuItem.modifierGroups.length > 0">
        <orderly-modifiers-group *ngFor="let group of selectedMenuItem.modifierGroups"
                                 [group]="group"
                                 [currencyCode3]="selectedMenuItem.price.currency.code3"
                                 [readonly]="isReadonly"
                                 (selectionChanged)="selectedMenuItem.updateModifiers($event.group, $event.selectedModifiers)">
        </orderly-modifiers-group>
      </div>
    </div>
  </div>
</div>

import {NeverError, OverrideProperty} from '@orderly/shared';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {MenuItemIngredientModel} from './menu-item-ingredient.model';
import {OrderableMenuItemModifierModel} from './orderable-menu-item-modifier.model';
import {OrderableMenuItemModifierGroupModel} from './orderable-menu-item-modifier-group.model';
import {OrderedMenuItemSizeModel} from './ordered-menu-item-size.model';
import MenuItem = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItem;
import MenuItemTranslationDto = Orderly.RestaurantWeb.DataTransferObjects.Translations.MenuItemTranslationDto;
import MenuItemIngredient = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemIngredient;
import MenuItemModifierGroup = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemModifierGroup;
import MenuItemImage = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemImage;
import MenuItemImageThumb = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemImageThumb;

type MenuItemModelWithOverriddenIngredients = OverrideProperty<MenuItem, 'ingredients', MenuItemIngredientModel[]>;
type MenuItemModelWithOverriddenModifierGroups = OverrideProperty<MenuItemModelWithOverriddenIngredients, 'modifierGroups', OrderableMenuItemModifierGroupModel[]>;
type MenuItemModelWithOverriddenSizes = OverrideProperty<MenuItemModelWithOverriddenModifierGroups, 'sizes', ReadonlyArray<OrderedMenuItemSizeModel>>;
type MenuItemModelWithOverriddenAllergens = OverrideProperty<MenuItemModelWithOverriddenSizes, 'allergens', ReadonlyArray<Orderly.RestaurantWeb.Api.Messages.Public.Models.Allergen>>;
type MenuItemModelWithOverriddenImages = OverrideProperty<MenuItemModelWithOverriddenAllergens, 'images', ReadonlyArray<MenuItemImage>>;

export class OrderableMenuItemModel implements Omit<MenuItemModelWithOverriddenImages, 'translations'> {

  private readonly _activeLangCode2: string;
  private readonly _activeTranslation: MenuItemTranslationDto | null;

  private _orderedMenuItemCount: number = 1;
  private _orderedSize: OrderedMenuItemSizeModel | null = null;
  private _orderedModifierGroups: OrderableMenuItemModifierGroupModel[] = [];


  public readonly ingredients: MenuItemIngredientModel[];

  public readonly modifierGroups: OrderableMenuItemModifierGroupModel[];

  public readonly sizes: OrderedMenuItemSizeModel[];


  public totalPrice: Orderly.Shared.Api.Messages.PriceDef | null = null;

  public isValid: boolean = false;

  public get name(): string {
    return this._activeTranslation?.name || this.defaultName;
  }

  public get description(): string | null {
    return this._activeTranslation?.description || this.defaultDescription;
  }

  public get ingredientsListAsText(): string {
    return OrderableMenuItemModel.getIngredientsListAsText(this.ingredients);
  }

  public get lowestPrice(): Orderly.Shared.Api.Messages.PriceDef {
    let result: Orderly.Shared.Api.Messages.PriceDef;

    if (this.sizes.length > 0) {
      const sizesSortedByPrice = [...this.sizes].sort((a, b) => a.price.value - b.price.value);

      result = sizesSortedByPrice[0].price;
    } else {
      result = this.price;
    }

    return result;
  }

  public get orderedMenuItemCount(): number {
    return this._orderedMenuItemCount;
  }

  public get orderedSize(): OrderedMenuItemSizeModel | null {
    return this._orderedSize;
  }

  public get orderedModifierGroups(): OrderableMenuItemModifierGroupModel[] {
    return this._orderedModifierGroups;
  }

  public get orderedQuantity(): Orderly.Shared.Api.Messages.ProductQuantityDef | null {

    if (this._orderedSize != null) {
      return this._orderedSize.quantity;
    }

    return this.quantity;
  }

  public get notEmptyInfo(): boolean {
    return (this.description != null && this.description !== '') ||
           this.thumb != null ||
           this.sizes.length > 0 ||
           this.ingredients.length > 0 ||
           this.allergens.length > 0 ||
           this.modifierGroups.length > 0;
  }

  private static getIngredientsListAsText(ingredients: ReadonlyArray<MenuItemIngredientModel>): string {
    let result: string = '';

    for (let i = 0; i < ingredients.length; i++) {
      result += ingredients[i].name;

      if (i < ingredients.length - 1) {
        result += ', ';
      }
    }

    return result;
  }


  private constructor(activeLangCode2: string,

                      public readonly externalId: string,
                      public readonly displayType: Orderly.Common.Enums.MenuCategoryItemsThumbVisibilityDef,
                      public readonly containsAlcohol: boolean,
                      public readonly price: Orderly.Shared.Api.Messages.PriceDef,
                      public readonly quantity: Orderly.Shared.Api.Messages.ProductQuantityDef | null,
                      private readonly sourceSizes: ReadonlyArray<Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemSize>,
                      public readonly allergens: ReadonlyArray<Orderly.RestaurantWeb.Api.Messages.Public.Models.Allergen>,
                      public readonly thumb: MenuItemImageThumb | null,
                      public readonly images: ReadonlyArray<MenuItemImage>,

                      public readonly parentMenuCategoryId: string,

                      private readonly sourceModifierGroups: ReadonlyArray<MenuItemModifierGroup>,
                      private readonly sourceIngredients: ReadonlyArray<MenuItemIngredient>,
                      private readonly defaultName: string,
                      private readonly defaultDescription: string | null,
                      private readonly translations: ReadonlyArray<MenuItemTranslationDto>,

                      orderedSize: OrderedMenuItemSizeModel | null,
                      orderedModifierGroups: OrderableMenuItemModifierGroupModel[],
                      orderedMenuItemCount: number = 1) {

    this._activeLangCode2 = activeLangCode2;
    this._activeTranslation = OrderableMenuItemModel.getActiveTranslation(activeLangCode2, translations);

    this._orderedSize = orderedSize;
    this._orderedModifierGroups = orderedModifierGroups;
    this._orderedMenuItemCount = orderedMenuItemCount;

    this.ingredients = sourceIngredients.map(x => new MenuItemIngredientModel(activeLangCode2, x.allergens, x.containsAlcohol, x.name, x.translations));
    this.modifierGroups = sourceModifierGroups.map(x => OrderableMenuItemModifierGroupModel.from(activeLangCode2, x, price.currency));
    this.sizes = sourceSizes.map(x => new OrderedMenuItemSizeModel(x.amount, quantity == null ? null : quantity.measureUnit, x.externalId, x.name, x.price, price.currency, x.sortingOrder));

    this.updateTotalPrice();
    this.updateIsValid();
  }

  public changedOrderedMenuItemCount(count: number): void {
    this._orderedMenuItemCount = count;

    this.updateTotalPrice();
    this.updateIsValid();
  }

  public selectSize(size: OrderedMenuItemSizeModel): void {
    this._orderedSize = size;

    this.updateTotalPrice();
    this.updateIsValid();
  }

  public updateModifiers(group: OrderableMenuItemModifierGroupModel, newGroupModifiers: OrderableMenuItemModifierModel[]) {

    const groupedModifiers: OrderableMenuItemModifierGroupModel[] = [...this._orderedModifierGroups];
    const existingGroupModifiersIndex: number = groupedModifiers.findIndex((m) => m.uniqueIdLowercase === group.uniqueIdLowercase);

    if (existingGroupModifiersIndex > -1) {
      const existingGroup: OrderableMenuItemModifierGroupModel = groupedModifiers[existingGroupModifiersIndex];
      const comparisonResults = this.compareModifiers(existingGroup.orderedModifiers, newGroupModifiers);
      const existingModifiers = existingGroup.orderedModifiers.filter((m) => {
        const deletedModifierIndex = comparisonResults.removed.findIndex((rm) => rm.uniqueIdLowercase === m.uniqueIdLowercase);

        return deletedModifierIndex < 0;
      });

      const allModifiers = existingModifiers.concat(comparisonResults.added);

      groupedModifiers[existingGroupModifiersIndex] = existingGroup.cloneWithReplacedModifiers(allModifiers);

      this._orderedModifierGroups = groupedModifiers;
    } else {
      const groupedModifiersCopy = [...groupedModifiers];

      groupedModifiersCopy.push(group.cloneWithReplacedModifiers(newGroupModifiers));

      this._orderedModifierGroups = groupedModifiersCopy;
    }

    this.updateTotalPrice();
    this.updateIsValid();
  }

  public clone(): OrderableMenuItemModel {
    const result = new OrderableMenuItemModel(this._activeLangCode2,
                                              this.externalId,
                                              this.displayType,
                                              this.containsAlcohol,
                                              this.price,
                                              this.quantity,
                                              this.sourceSizes,
                                              this.allergens,
                                              this.thumb,
                                              this.images,
                                              this.parentMenuCategoryId,
                                              this.sourceModifierGroups,
                                              this.sourceIngredients,
                                              this.defaultName,
                                              this.defaultDescription,
                                              this.translations,
                                              this._orderedSize,
                                              this._orderedModifierGroups,
                                              this._orderedMenuItemCount);

    return result;
  }

  public cloneAndChangeActiveLanguage(langCode2: string): OrderableMenuItemModel {

    const orderedModifierGroups = this._orderedModifierGroups.map((omg) => omg.cloneAndChangeActiveLanguage(langCode2));

    const result = new OrderableMenuItemModel(langCode2,
                                              this.externalId,
                                              this.displayType,
                                              this.containsAlcohol,
                                              this.price,
                                              this.quantity,
                                              this.sourceSizes,
                                              this.allergens,
                                              this.thumb,
                                              this.images,
                                              this.parentMenuCategoryId,
                                              this.sourceModifierGroups,
                                              this.sourceIngredients,
                                              this.defaultName,
                                              this.defaultDescription,
                                              this.translations,
                                              this._orderedSize,
                                              orderedModifierGroups,
                                              this._orderedMenuItemCount);

    return result;
  }

  private updateIsValid(): void {
    if (this._orderedMenuItemCount <= 0) {
      this.isValid = false;

      return;
    }

    if (this.sizes.length > 0 && this._orderedSize == null) {
      this.isValid = false;

      return;
    }

    const invalidModifierGroups = this.modifierGroups
                                      .filter(x => {

                                        const orderedGroup = this._orderedModifierGroups.find(
                                          om => om.uniqueIdLowercase === x.uniqueIdLowercase);

                                        if (orderedGroup != null) {
                                          return !orderedGroup.isValid;
                                        }

                                        switch (x.selectionMode) {
                                          case 'exactly-one':
                                          case 'at-least-one':
                                            // group is valid, because none of its modifiers was yet ordered
                                            return true;

                                          case 'zero-or-one':
                                          case 'zero-or-more':
                                            // group is valid, because none of its modifiers was yet ordered
                                            return false;

                                          default:
                                            throw new NeverError(x.selectionMode);
                                        }
                                      });

    this.isValid = invalidModifierGroups.length === 0;
  }

  private updateTotalPrice(): void {
    if (this.sizes.length > 0 && this._orderedSize == null) {

      this.totalPrice = null;

      return;
    }

    const orderedModifiersPriceTotal = this._orderedModifierGroups.reduce(
      (prev: number, curr: OrderableMenuItemModifierGroupModel) => {
        return prev + curr.totalPrice;
      }, 0);

    const menuItemPrice = this._orderedSize?.price.value || this.price.value;
    const menuItemPriceWithModifiers = menuItemPrice + orderedModifiersPriceTotal;
    const menuItemPriceTotal = menuItemPriceWithModifiers * this._orderedMenuItemCount;
    const result: Orderly.Shared.Api.Messages.PriceDef = {
      currency: this.price.currency,
      value: menuItemPriceTotal
    };

    this.totalPrice = result;
  }

  private compareModifiers(existingModifiers: OrderableMenuItemModifierModel[],
                           newModifiers: OrderableMenuItemModifierModel[]): { added: OrderableMenuItemModifierModel[], removed: OrderableMenuItemModifierModel[] } {


    const deletedModifiers = existingModifiers.filter((em) => {

      const existingModifierIndex = newModifiers.findIndex((nm) => nm.uniqueIdLowercase === em.uniqueIdLowercase);

      // if new modifiers do not contain an existing modifier, then it was to be removed
      return existingModifierIndex < 0;
    });

    const addedModifiers = newModifiers.filter((nm) => {

      const newModifierIndex = existingModifiers.findIndex((em) => nm.uniqueIdLowercase === em.uniqueIdLowercase);

      // if existing modifiers do not contain a new modifier, then it was added
      return newModifierIndex < 0;
    });

    return {added: addedModifiers, removed: deletedModifiers};
  }

  private static getActiveTranslation(langCode2: string,
                                      translations: ReadonlyArray<MenuItemTranslationDto>) {

    const activeTranslationIdx = translations.findIndex(x => x.languageCode2.toLowerCase() == langCode2.toLowerCase());
    const activeTranslation = activeTranslationIdx < 0 ? null : translations[activeTranslationIdx];

    return activeTranslation;
  }

  public static from(activeLangCode2: string,
                     parentMenuCategoryId: string,
                     source: Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItem,
                     displayType: Orderly.Common.Enums.MenuCategoryItemsThumbVisibilityDef): OrderableMenuItemModel {

    return new OrderableMenuItemModel(activeLangCode2,
                                      source.externalId,
                                      displayType,
                                      source.containsAlcohol,
                                      source.price,
                                      source.quantity,
                                      source.sizes,
                                      source.allergens,
                                      source.thumb,
                                      source.images,

                                      parentMenuCategoryId,

                                      source.modifierGroups,
                                      source.ingredients,
                                      source.name,
                                      source.description,
                                      source.translations,
                                      null,
                                      [],
                                      1);

  }

}

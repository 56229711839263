import {Orderly} from '@orderly/shared-menu-api-interfaces';
import Language2 = Orderly.Shared.Api.Messages.Language2;

export interface FailedRetry {
  count: number;
  lastFailedOnUnixTime: number;
}

export interface AppSettingsState {
  ordersEnabled: boolean;
  lastUpdatedOnUnixTime: number | null;
  fail: FailedRetry | null;
  appUILanguages: Language2[];
  selectableLanguages: Language2[];
  defaultLanguage: Language2 | null;
}

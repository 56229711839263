<div class="quantity-box">
  <span class="quantity__text">{{'Quantity' | translate}}</span>

  <div class="quantity__control">

    <a class="quantity__control-btn subtract"
       [attr.disabled]="isSubstractDisabled"
       (click)="substract()">
      <i class="icon-left-open"></i>
    </a>

    <span class="quantity__value">{{quantity}}</span>

    <a class="quantity__control-btn add"
       [class.disabled]="isSubstractDisabled"
       (click)="add()">
      <i class="icon-right-open"></i>
    </a>

  </div>

</div>

import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectMenuFeatureCart, triggerCleanupAfterNoInteractionFromUserAction} from '@orderly/shared-menu-store';
import {BehaviorSubject, catchError, delay, EMPTY, first, iif, map, mergeMap, Subject, Subscription, switchMap, tap, throwError, timer} from 'rxjs';
import {environment} from '../../environments/environment';

const minutesToWaitUntilCartIsAutoCleared = environment.production || environment.test ? 5 : 1;

export const CLEANUP_MODAL_VISIBILITY_TIMEOUT_MS = 30000;

@Injectable({
              providedIn: 'root'
            })
export class AutocleanupService implements OnDestroy {

  private readonly cartChangeStream$: Subject<Date | 'canceled'> = new Subject<Date | 'canceled'>();

  private readonly cartChangeStreamSubscription: Subscription;

  public readonly showCleanupConfirmationDialog$ = new BehaviorSubject(false);


  constructor(private readonly store: Store<any>) {

    const cleanupAfterDelay$ = timer(minutesToWaitUntilCartIsAutoCleared * 60 * 1000)
      .pipe(
        tap(() => {
          this.showCleanupConfirmationDialog$.next(true);
        }),

        delay(CLEANUP_MODAL_VISIBILITY_TIMEOUT_MS),

        tap(() => {
          store.dispatch(triggerCleanupAfterNoInteractionFromUserAction());

          this.showCleanupConfirmationDialog$.next(false);
        }),
      );

    const scheduleCleanupIfCartNotEmpty$ = store.select(selectMenuFeatureCart)
                                                .pipe(
                                                  first(),
                                                  mergeMap(x => {
                                                             return x.orderedItems.length === 0 ? EMPTY : cleanupAfterDelay$;
                                                           }
                                                  ),
                                                );

    this.cartChangeStreamSubscription = this.cartChangeStream$
                                            .pipe(
                                              tap(() => {
                                                this.showCleanupConfirmationDialog$.next(false);
                                              }),

                                              switchMap((lastChangedOn) => {

                                                if (lastChangedOn === 'canceled') {
                                                  return EMPTY;
                                                }

                                                return scheduleCleanupIfCartNotEmpty$;
                                              }),

                                              catchError((err, _) => {

                                                this.showCleanupConfirmationDialog$.next(false);

                                                return _;
                                              }),
                                            )
                                            .subscribe();
  }


  public registerCartChange(): void {
    this.cartChangeStream$.next(new Date());
  }

  public delayCleanup(): void {
    this.cartChangeStream$.next(new Date());
  }

  public cancelCleanup(): void {
    this.cartChangeStream$.next('canceled');
  }

  public clearCart(): void {
    this.store.dispatch(triggerCleanupAfterNoInteractionFromUserAction());
    this.showCleanupConfirmationDialog$.next(false);
  }


  ngOnDestroy(): void {
    this.cartChangeStreamSubscription.unsubscribe();
  }

}

import {Store} from '@ngrx/store';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {catchError, EMPTY, Observable, tap} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {failAppSettingsLoadAction, updateAppSettingsAction} from '@orderly/shared-menu-store';
import {APP_INITIALIZER, Provider} from '@angular/core';
import {AppSettingsService} from '@orderly/shared';
import Language2 = Orderly.Shared.Api.Messages.Language2;

function getInitAppAction(appSettingsService: AppSettingsService, store: Store<any>): Observable<any> {

  const initialLoadingBlockEl = window.document.getElementById('initial-loading-block');

  return appSettingsService.getAppSettings('FROM_APP_INITIALIZER')
                           .pipe(
                             tap((x) => {

                               initialLoadingBlockEl?.remove();

                               const uiLanguages: Language2[] = x.uiLanguages.map(x => {
                                 return {
                                   code2: x.code2.toLowerCase(),
                                   name: x.name
                                 };
                               })

                               store.dispatch(updateAppSettingsAction(
                                 {
                                   loadedOnUnixTime: x.loadedOnUnixTime,
                                   uiLanguages: uiLanguages,
                                 }));
                             }),
                             catchError((err) => {

                               console.log('getInitAppAction catchError');

                               initialLoadingBlockEl?.remove();

                               if (err instanceof HttpErrorResponse) {
                                 if (err.status === 0) {
                                   store.dispatch(failAppSettingsLoadAction({reason: 'NO_CONNECTION'}));
                                 }
                               } else {
                                 store.dispatch(failAppSettingsLoadAction({reason: 'UNKNOWN'}));
                               }

                               return EMPTY;
                             }),
                           );
}

function initAppFactory(appSettingsService: AppSettingsService, store: Store<any>) {
  return () => getInitAppAction(appSettingsService, store);
}


export const appInitializerServiceProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initAppFactory,
  deps: [AppSettingsService, Store],
  multi: true,
};

import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AppRouteData, RequireAppSettingsGuard} from '@orderly/shared';
import {RequireMenuGuard} from '@orderly/shared-client-menu';
import {appStartRoutePath, cartRoutePath, menuRoutePath, serviceZoneRoutePath} from './app-routing-constants';
import {AppStartComponent} from './components/app-start/app-start.component';
import {MainShellComponent} from './containers/main-shell/main-shell.component';

const menuRequireAppSettingsGuardRedirectRouteData = RequireAppSettingsGuard.getRouteDataForRedirect(`/${appStartRoutePath}`);
const menuRequireMenuGuardRedirectRouteData = RequireMenuGuard.getRouteDataForRedirect(`/${appStartRoutePath}`);

const menuRouteData = {
  ...menuRequireAppSettingsGuardRedirectRouteData,
  ...menuRequireMenuGuardRedirectRouteData,
  ...new AppRouteData('menu'),
};

const cartRouteData = {
  ...menuRequireAppSettingsGuardRedirectRouteData,
  ...menuRequireMenuGuardRedirectRouteData,
  ...new AppRouteData('cart'),
};

const routes: Routes = [
  {
    path: appStartRoutePath,
    component: AppStartComponent,
    pathMatch: 'full',
  },

  {
    path: serviceZoneRoutePath,

    loadChildren: () => import('@orderly/restaurant-kiosk-web-app-service-zone').then(m => m.RestaurantKioskWebAppServiceZoneModule),
  },

  {
    path: menuRoutePath,
    component: MainShellComponent,
    data: menuRouteData,

    canActivate: [
      RequireAppSettingsGuard,
      RequireMenuGuard,
    ],

    loadChildren: () => import('@orderly/shared-client-menu').then(m => m.SharedClientMenuModule),
  },

  {
    path: cartRoutePath,
    component: MainShellComponent,
    data: cartRouteData,

    canActivate: [
      RequireAppSettingsGuard,
      RequireMenuGuard,
    ],

    loadChildren: () => import('@orderly/shared-client-menu-cart').then(m => m.SharedClientMenuCartModule),
  },

  // errors
  // TODO:
  // {
  //   path: 'no-connection/:source',
  //   component: NoConnectionErrorComponent,
  //   pathMatch: 'full',
  // },


  {path: '', redirectTo: `/${appStartRoutePath}`, pathMatch: 'full'}
  // {path: '**', redirectTo: '/' + tableTokenRoutePath, pathMatch: 'full'}
];

@NgModule({
            imports: [
              RouterModule.forRoot(routes, {
                preloadingStrategy: PreloadAllModules,
                enableTracing: false,
                paramsInheritanceStrategy: 'always',
              }),
            ],
            exports: [RouterModule]
          })
export class AppRoutingModule {}

import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Data,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {map, Observable, of, timeout} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppSettingsState, reloadAppSettingsAction, selectMenuFeatureAppSettings} from '@orderly/shared-menu-store';
import {RouteDataForRedirect} from './route-data-for-redirect';

const RequireAppSettingsGuardRouteData = 'RequireAppSettingsGuardRouteData';

@Injectable({
              providedIn: 'root'
            })
export class RequireAppSettingsGuard implements CanActivate {

  public static getRouteDataForRedirect(redirectTo: string): Data {

    const data: RouteDataForRedirect = {
      redirectTo
    };

    const result = {};

    // @ts-ignore
    result[RequireAppSettingsGuardRouteData] = data;

    return result;
  }

  constructor(private readonly store: Store<any>,
              private readonly router: Router,
              private readonly logger: NGXLogger) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const appSettings$ = this.store.select(selectMenuFeatureAppSettings);

    return appSettings$.pipe(
      timeout({each: 4000, with: () => of(null)}),
      map((state: AppSettingsState | null) => {

        const redirectData: RouteDataForRedirect | null = route.data[RequireAppSettingsGuardRouteData];
        const redirectToUrlTree = redirectData == null ? null : this.router.parseUrl(redirectData.redirectTo);

        if (state == null) {
          this.logger.debug('RequireAppSettingsGuard: AppSettingsState is null');

          // state can be null during the initialization phase
          return redirectToUrlTree == null ? false : redirectToUrlTree;
        }

        if (state.lastUpdatedOnUnixTime == null) {
          this.logger.debug('RequireAppSettingsGuard: AppSettingsState.lastUpdatedOnUnixTime is null');

          if (state.fail) {
            this.store.dispatch(reloadAppSettingsAction({reason: 'AFTER_FAIL', retry: state.fail}));
          } else {
            this.store.dispatch(reloadAppSettingsAction({reason: 'NOT_LOADED'}));
          }

          return redirectToUrlTree == null ? false : redirectToUrlTree;
        }

        return true;
      })
    );
  }

}

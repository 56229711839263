import {AppSettingsState} from './app-settings-state';
import {FingerprintState} from './fingerprint-state';
import {TableState} from './table-state';


export interface MenuAppState {
  appSettings: AppSettingsState;
  clientFingerprint: FingerprintState;
  table: TableState;
}

<h1 *ngIf="!requestSent" class="request request-wait">{{'Calling a waiter' | translate}}...</h1>

<h1 *ngIf="requestSent" class="request request-success">{{'Your request was sent successfully' | translate}}</h1>

<div *ngIf="isCancelButtonVisible" class="text-center">
  <a (click)="cancel()" class="request request-cancel">
    {{'Cancel' | translate}}

    (<countdown #cd [config]="hideCancelButtonCountdownCfg" (event)="hideCancelButton($event)"></countdown>)
  </a>
</div>

import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ItemsListComponent} from './components/items-list/items-list.component';
import {CategoriesListComponent} from './components/categories-list/categories-list.component';
import {MenuRoutingData} from './menu-routing-data';

const routes: Routes = [
  {
    path: 'categories',
    component: CategoriesListComponent,
    pathMatch: 'full',
    data: new MenuRoutingData('categories-list', '../../')
  },
  {
    path: 'category/:categoryId',
    component: ItemsListComponent,
    pathMatch: 'full',
    data: new MenuRoutingData('category-details', '../../categories')
  },

  {path: '', redirectTo: `categories`, pathMatch: 'full'}
];

@NgModule({
            imports: [
              RouterModule.forChild(routes),
            ],
            exports: [RouterModule]
          })
export class SharedClientMenuRoutingModule {}

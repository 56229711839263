import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateModule} from '@ngx-translate/core';
import {
  AppSettingsService,
  blockingModalHttpRequestInterceptorProvider,
  getTranslateModuleConfig,
  SharedModule,
} from '@orderly/shared';
import {appInitializerServiceProvider} from '@orderly/shared-app-initializer';
import {SharedComponentsModule} from '@orderly/shared-components';
import {SharedMenuStoreModule} from '@orderly/shared-menu-store';
import {CountdownModule} from 'ngx-countdown';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {BlockUIModule} from 'primeng/blockui';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {InMemoryAppSettingsService} from './services/in-memory-app-settings.service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {AppStartComponent} from './components/app-start/app-start.component';
import {MainShellComponent} from './containers/main-shell/main-shell.component';
import {RightMenuComponent} from './components/right-menu/right-menu.component';
import {CallWaiterComponent} from './modals/call-waiter/call-waiter.component';
import {LanguageSelectorComponent} from './components/right-menu/language-selector/language-selector.component';
import {AutocleanupConfirmationComponent} from './components/autocleanup-confirmation/autocleanup-confirmation.component';
import {LanguagesListComponent} from './modals/languages-list/languages-list.component';
import {CartEffects} from './store/effects/cart-effects';
import {CartLastChangeTrackerEffects} from './store/effects/cart-last-change-tracker.effects';
import {LocalizationEffects} from './store/effects/localization-effects';

import localeDe from '@angular/common/locales/de';
import {NavigationEffects} from './store/effects/navigation-effects';

registerLocaleData(localeDe);

@NgModule({
            declarations: [
              AppComponent,
              AppStartComponent,
              MainShellComponent,
              RightMenuComponent,
              CallWaiterComponent,
              LanguageSelectorComponent,
              LanguagesListComponent,
              AutocleanupConfirmationComponent,
            ],
            imports: [
              AppRoutingModule,

              HttpClientModule,
              BrowserModule,
              BrowserAnimationsModule,

              SharedModule,
              SharedComponentsModule,
              SharedMenuStoreModule,

              EffectsModule.forRoot([
                                      LocalizationEffects,
                                      CartEffects,
                                      NavigationEffects,
                                      CartLastChangeTrackerEffects,
                                    ]),

              StoreModule.forRoot({
                                    router: routerReducer,
                                  },
                                  {
                                    runtimeChecks: {
                                      strictActionTypeUniqueness: true,
                                      strictActionImmutability: true,
                                      strictActionSerializability: false,
                                      strictActionWithinNgZone: true,
                                      strictStateImmutability: true,
                                      strictStateSerializability: false,
                                    }
                                  }),

              LoggerModule.forRoot({
                                     level: NgxLoggerLevel.DEBUG,
                                     disableConsoleLogging: environment.production,
                                   }),

              StoreRouterConnectingModule.forRoot(),
              StoreDevtoolsModule.instrument({
                                               maxAge: 30,
                                               logOnly: environment.production
                                             }),

              ServiceWorkerModule.register('ngsw-worker.js', {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the app is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: 'registerWhenStable:30000'
              }),

              TranslateModule.forRoot(getTranslateModuleConfig(!environment.production)),

              CountdownModule,
              DynamicDialogModule,
              BlockUIModule,
            ],
            providers: [
              blockingModalHttpRequestInterceptorProvider,
              appInitializerServiceProvider,

              {provide: AppSettingsService, useClass: InMemoryAppSettingsService},
              {provide: LOCALE_ID, useValue: 'de-DE'},
            ],
            bootstrap: [AppComponent],
          })
export class AppModule {}

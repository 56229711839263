import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {
  markCurrentOrderAsCompletedAction,
  markCurrentOrderAsFailedToSubmitAction,
  requestNavigateToMenuAction,
  submitOrderAction,
  SubmitOrderActionPayload,
  triggerCleanupAfterNoInteractionFromUserAction
} from '@orderly/shared-menu-store';
import {NGXLogger} from 'ngx-logger';
import {catchError, exhaustMap, map, of} from 'rxjs';
import {KioskService} from '../../services/kiosk.service';
import CreateOrderResponse = Orderly.RestaurantWeb.Api.Messages.Public.CreateOrderResponse;
import StatusDef = Orderly.RestaurantWeb.Api.Messages.Public.CreateOrderResponse.StatusDef;

@Injectable()
export class CartEffects {

  constructor(private readonly actions$: Actions,
              private readonly kioskService: KioskService,
              private readonly logger: NGXLogger) {
  }

  submitOrder$ = createEffect(() => this.actions$.pipe(
                                ofType(submitOrderAction.type),
                                exhaustMap((x: SubmitOrderActionPayload) => {

                                  return this.kioskService.submitOrder(x.items);
                                }),
                                map((response: CreateOrderResponse) => {
                                  switch (response.status) {
                                    case StatusDef.Success:
                                      return markCurrentOrderAsCompletedAction();

                                    case StatusDef.UnknownFailure:
                                    case StatusDef.ValidationFailed:
                                      this.logger.error('Not expected error while submitting the order', response);

                                      return markCurrentOrderAsFailedToSubmitAction();
                                  }
                                }),
                                catchError((err, _) => {

                                  this.logger.error('Failed to submit the order', err);

                                  return of(markCurrentOrderAsFailedToSubmitAction());
                                })
                              ),
  );

  markCurrentOrderAsCompleted$ = createEffect(() => this.actions$.pipe(
                                                ofType(markCurrentOrderAsCompletedAction.type),
                                                map((response: CreateOrderResponse) => {
                                                  switch (response.status) {
                                                    case StatusDef.Success:
                                                      return markCurrentOrderAsCompletedAction();

                                                    case StatusDef.UnknownFailure:
                                                    case StatusDef.ValidationFailed:
                                                      throw new Error();
                                                  }
                                                })
                                              ), {dispatch: false}
  );


  triggerCleanupAfterNoInteractionFromUser$ = createEffect(() => this.actions$.pipe(
                                                             ofType(triggerCleanupAfterNoInteractionFromUserAction.type),
                                                             map(() => {
                                                               return requestNavigateToMenuAction();
                                                             })
                                                           ),
  );

}

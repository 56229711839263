import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {catchError, filter, map, Observable, of, timeout} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  FingerprintState,
  generateClientFingerprintAction,
  selectMenuFeatureClientFingerprint
} from '@orderly/shared-menu-store';


@Injectable({
              providedIn: 'root'
            })
export class RequireClientFingerprintGuard implements CanActivate {

  constructor(private readonly store: Store<any>,
              private readonly logger: NGXLogger) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const fingerprint$ = this.store.select(selectMenuFeatureClientFingerprint);

    return fingerprint$.pipe(
      timeout(2000),
      filter(x => x != null),
      map((state: FingerprintState | null) => {
        return true;
      }),
      catchError((err) => {

        this.logger.error(err);

        this.store.dispatch(generateClientFingerprintAction());

        return of(false);
      })
    );
  }

}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {map, Observable, of, timeout} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectMenuFeatureMenu} from '@orderly/shared-menu-store';

interface RouteDataForRedirect {
  redirectTo: string;
}

const RequireMenuGuardRouteData: string = 'RequireMenuGuard';

@Injectable({
              providedIn: 'root'
            })
export class RequireMenuGuard implements CanActivate {

  public static getRouteDataForRedirect(redirectTo: string): Data {

    const data: RouteDataForRedirect = {
      redirectTo
    };

    const result = {};

    // @ts-ignore
    result[RequireMenuGuardRouteData] = data;

    return result;
  }

  constructor(private readonly store: Store<any>,
              private readonly router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const menu$ = this.store.select(selectMenuFeatureMenu);

    return menu$.pipe(
      timeout({each: 500, with: () => of(null)}),
      map((state: Orderly.RestaurantWeb.Api.Messages.Public.Models.RestaurantMenu | null) => {

        if (state == null) {

          console.log('RequireMenuGuard - not allowed - state is null');

          const redirectData: RouteDataForRedirect | null = route.data[RequireMenuGuardRouteData];
          const redirectToUrlTree = redirectData == null ? null : this.router.parseUrl(redirectData.redirectTo);

          // state can be null during the initialization phase
          return redirectToUrlTree == null ? false : redirectToUrlTree;
        }

        console.log('RequireMenuGuard - allowed');

        return true;
      })
    );
  }

}

import {
  HTTP_INTERCEPTORS,
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Injectable, Provider} from '@angular/core';
import {catchError, delay, mergeMap, Observable, of, tap, throwError} from 'rxjs';
import {ModalProgressService} from '../services/modal-progress.service';
import {AppSettingsService} from '../services/app-settings.service';

export const BLOCK_WINDOW_CONTEXT_KEY = new HttpContextToken<boolean>(() => true);

@Injectable()
export class BlockingModalHttpRequestInterceptor implements HttpInterceptor {

  constructor(private readonly progressModalService: ModalProgressService,
              private readonly appSettingsService: AppSettingsService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request$ = of(request);

    if (this.appSettingsService.isDevEnvironment()) {
      console.log(`Added 2s delay for ${request.url}`);

      request$ = request$.pipe(
        delay(2000),
      );
    }

    const shouldBlockWindow = request.context.get(BLOCK_WINDOW_CONTEXT_KEY);

    if (shouldBlockWindow) {
      const blockingModalToken = this.progressModalService.display();

      return request$.pipe(
        mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken)),
        tap(() => {
          this.progressModalService.hide(blockingModalToken);
        }),
        catchError((err) => {
          this.progressModalService.hide(blockingModalToken);

          return throwError(err);
        })
      );
    }

    return request$.pipe(
      mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken)),
    );
  }
}

export const blockingModalHttpRequestInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BlockingModalHttpRequestInterceptor,
  multi: true,
};

import {Component, Optional} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {
  addCartMenuItemAction,
  OrderableMenuItemModel, registerMenuItemViewAction,
  selectMenuFeatureMenu, selectMenuFeatureTable,
  selectOrdersEnabled
} from '@orderly/shared-menu-store';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, map, Observable, startWith} from 'rxjs';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {MenuConfigurationService} from '../../service/menu-configuration.service';
import {MenuCategoryModel} from '../categories-list/model/menu-category.model';
import RestaurantMenu = Orderly.RestaurantWeb.Api.Messages.Public.Models.RestaurantMenu;

@Component({
             selector: 'orderly-items-list',
             templateUrl: './items-list.component.html',
             styleUrls: ['./items-list.component.scss']
           })
export class ItemsListComponent {

  public categoryWithItems$: Observable<MenuCategoryModel>;

  public selectedMenuItem$ = new BehaviorSubject<OrderableMenuItemModel | null>(null);

  public selectedMenuItemHasModifiers$: Observable<boolean> = this.selectedMenuItem$.pipe(map(x => {
    if (x == null) {
      return false;
    }

    return x.modifierGroups.length > 0;
  }));

  public isReadonly$: Observable<boolean> = this.store.select(selectOrdersEnabled).pipe(map(x => !x));

  public showBackButton = true;

  constructor(translateService: TranslateService,
              private readonly store: Store<any>,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              @Optional() menuConfigurationService: MenuConfigurationService) {

    this.showBackButton = (menuConfigurationService?.config.backNavigationControlledBy ?? 'self') === 'self';

    const categoryId$ = activatedRoute.paramMap.pipe(map(x => x.get('categoryId')));
    const menu$ = store.select(selectMenuFeatureMenu).pipe(map(x => x!));
    const onLangCodeChange$ = translateService.onLangChange
                                              .pipe(
                                                map(x => x.lang),
                                                startWith(translateService.currentLang),
                                              );


    this.categoryWithItems$ = combineLatest([categoryId$, menu$, onLangCodeChange$])
      .pipe(
        map((x: [string | null, RestaurantMenu, string]) => {
          const categoryId = x[0];
          const menu = x[1];
          const currentLang2 = x[2];

          const categoryIdx = menu.categories.findIndex(c => c.externalId == categoryId);

          if (categoryIdx < 0) {
            throw new Error(`Category [${categoryId}] was not found`);
          }

          const category = menu.categories[categoryIdx];

          return MenuCategoryModel.from(currentLang2, category, menu.categoriesDisplayType);
        })
      );
  }

  public hideMenuItemDetails(): void {

    this.selectedMenuItem$.next(null);
  }

  public showDetails(item: OrderableMenuItemModel): void {

    this.store.dispatch(registerMenuItemViewAction({itemId: item.externalId}));

    this.selectedMenuItem$.next(item.clone());
  }

  public backToCategoriesList(): void {
    this.router.navigate(['../../categories'], {relativeTo: this.activatedRoute});
  }

  public addToCart(item: OrderableMenuItemModel): void {
    this.store.dispatch(addCartMenuItemAction({item}));

    this.selectedMenuItem$.next(null);
  }

  public itemTrackBy(index: number, item: OrderableMenuItemModel): string {
    return item.externalId;
  }
}

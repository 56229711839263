import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {ModalProgressService, NeverError, ProgressDialogToken} from '@orderly/shared';
import {BaseComponent} from '@orderly/shared-components';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {updateTableStateAction} from '@orderly/shared-menu-store';
import {CountdownComponent, CountdownConfig, CountdownEvent} from 'ngx-countdown';
import {catchError, exhaustMap, Observable, of, Subject, switchMap, tap} from 'rxjs';
import {menuRoutePath} from '../../app-routing-constants';
import {KioskService} from '../../services/kiosk.service';
import QrMenuActionTypeDef = Orderly.Common.Enums.QrMenuActionTypeDef;
import TableClientRequestTypeDef = Orderly.Common.Enums.TableClientRequestTypeDef;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Component({
             selector: 'orderly-kiosk-app-start',
             templateUrl: './app-start.component.html',
             styleUrls: ['./app-start.component.scss']
           })
export class AppStartComponent extends BaseComponent implements OnInit {

  private modalToken!: ProgressDialogToken;

  private readonly countdownTimeSeconds = 10;

  private readonly updateSettingsAndNavigateToMenu$ = new Subject<void>();


  public readonly countdownConfig: CountdownConfig = {
    demand: false,
    format: 'mm:ss',
    leftTime: this.countdownTimeSeconds,
  };


  @ViewChild('cd', {static: false})
  // @ts-ignore
  private countdownComponent: CountdownComponent;


  constructor(private readonly progressService: ModalProgressService,
              private readonly kioskService: KioskService,
              private readonly store: Store<any>,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute) {

    super();

    this.registerForAutoDestroy(
      this.updateSettingsAndNavigateToMenu$
          .pipe(
            exhaustMap((_, i) => {

              return this.tryUpdateSettingsAndNavigateToMenu();
            }),
            tap((navigationStatus) => {

              switch (navigationStatus) {
                case 'no-device-id':
                  this.countdownComponent.restart();
                  this.countdownComponent.begin();
                  break;
                case true:
                  this.progressService.hide(this.modalToken);
                  break;
                case false:
                  // TODO: navigate to technical error or reload the page???
                  break;
                default:
                  throw new NeverError(navigationStatus);
              }
            }),
            catchError((err, _) => {
              console.log(err);

              if (!this.countdownConfig.leftTime || this.countdownConfig.leftTime < this.countdownTimeSeconds * 6) {
                this.countdownConfig.leftTime = (this.countdownConfig.leftTime || 0) + this.countdownTimeSeconds;
                this.countdownComponent.config = this.countdownConfig;
              }

              this.countdownComponent.restart();
              this.countdownComponent.begin();

              return _;
            }),
          )
          .subscribe()
    );
  }

  ngOnInit() {
    this.modalToken = this.progressService.display();

    this.updateSettingsAndNavigateToMenu$.next();
  }

  private getDeviceInfo(): { deviceId: string, firstLaunch: boolean, appVersion: string } | null {
    const bodyTag = document.getElementsByTagName('body')[0];

    const deviceId = bodyTag.getAttribute('device-id');

    if (deviceId == null || deviceId === '') {
      return null;
    }

    const firstLaunch = bodyTag.getAttribute('first-launch') === 'true';
    const appVersion = bodyTag.getAttribute('app-version') || 'version-not-available';

    return {deviceId, firstLaunch, appVersion};
  }

  private tryUpdateSettingsAndNavigateToMenu(): Observable<boolean | 'no-device-id'> {
    const deviceInfo = this.getDeviceInfo();

    if (deviceInfo != null) {

      return this.kioskService
                 .loadSettingsAndMenu(deviceInfo.deviceId)
                 .pipe(
                   tap((settingsAndMenu) => {
                     console.log('Loaded menu settings');

                     const tokenValidUntilUtc = dayjs.utc().add(31, 'day');
                     const actions: Orderly.RestaurantWeb.Api.Messages.Public.GetTableGuestActionsResponse.GuestActionDefinition[] = [];

                     if (settingsAndMenu.settings.callWaiterEnabled) {
                       actions.push({
                                      type: TableClientRequestTypeDef.CallWaiter,
                                      translations: [],
                                      defaultName: {
                                        text: '',
                                        langCode2: ''
                                      }
                                    });
                     }

                     this.store
                         .dispatch(updateTableStateAction({
                                                            menu: settingsAndMenu.menu,
                                                            actions,
                                                            apiCallToken: settingsAndMenu.deviceToken,
                                                            apiCallTokenValidUntilUtc: tokenValidUntilUtc,
                                                            menuButtonAction: QrMenuActionTypeDef.OnlineMenu,
                                                            tableToken: settingsAndMenu.tableToken,
                                                            ordersEnabled: settingsAndMenu.settings.ordersEnabled,
                                                            tableName: settingsAndMenu.tableName,
                                                          }));
                   }),
                   switchMap((x) => {

                     // TODO: from(...) does not complete at all
                     this.router.navigate([`/${menuRoutePath}`], {relativeTo: this.activatedRoute});

                     return of(true);
                   }),
                 );
    }

    return of('no-device-id');
  }

  public handleCountdownEvent($event: CountdownEvent): void {

    if ($event.action === 'done') {

      this.updateSettingsAndNavigateToMenu$.next();
    }
  }
}

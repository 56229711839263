<div class="modal">
  <div class="modal__message">
    <p>
      {{'Cart will be cleared.' | translate}}
    </p>

    <p>
      {{'Keep selected items in shopping cart?' | translate}}
    </p>
  </div>

  <div class="modal__actions">
    <button class="button modal__actions-clear" (click)="clearCart()">
      <span>
        {{'Clear cart' | translate}}
      </span>
      <span>
        <countdown #cd [config]="countdownConfig" (event)="handleCountdownEvent($event)"></countdown>
      </span>
    </button>

    <button class="button modal__actions-keep" (click)="keepItems()">
      {{'Keep items' | translate}}
    </button>
  </div>
</div>

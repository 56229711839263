import {OverrideProperty} from '@orderly/shared';
import {Orderly} from '@orderly/shared-menu-api-interfaces';

import MenuItemModifier = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemModifier;
import RestaurantMenuItemModifierTranslationDto = Orderly.RestaurantWeb.DataTransferObjects.Translations.RestaurantMenuItemModifierTranslationDto;
import PriceDef = Orderly.Shared.Api.Messages.PriceDef;
import ProductQuantityDef = Orderly.Shared.Api.Messages.ProductQuantityDef;
import Currency = Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Currency;

type MenuItemModifierWithoutTranslationsAndMeasureUnit = Omit<MenuItemModifier, 'translations' | 'measureUnit'>;
type MenuItemModifierWithUpdatedPriceDiffType = OverrideProperty<MenuItemModifierWithoutTranslationsAndMeasureUnit, 'priceDiff', PriceDef>;
type MenuItemModifierWithUpdatedAmountType = OverrideProperty<MenuItemModifierWithUpdatedPriceDiffType, 'amount', ProductQuantityDef | null>;

export class OrderableMenuItemModifierModel implements MenuItemModifierWithUpdatedAmountType {

  private readonly _activeTranslation: RestaurantMenuItemModifierTranslationDto | null;

  private readonly _activeLangCode2: string;


  public get name(): string {
    return this._activeTranslation?.name || this.defaultName;
  }

  public readonly uniqueIdLowercase: string;

  public get uniqueId(): string {
    return this.uniqueIdLowercase;
  }

  private constructor(activeLangCode2: string,
                      uniqueId: string,
                      public readonly amount: ProductQuantityDef | null,
                      public readonly priceDiff: PriceDef,
                      private readonly defaultName: string,
                      private readonly translations: RestaurantMenuItemModifierTranslationDto[]) {

    this._activeTranslation = OrderableMenuItemModifierModel.getActiveTranslation(activeLangCode2, translations);
    this._activeLangCode2 = activeLangCode2;
    this.uniqueIdLowercase = uniqueId.toLowerCase();
  }

  public cloneAndChangeActiveLanguage(langCode2: string): OrderableMenuItemModifierModel {

    return new OrderableMenuItemModifierModel(langCode2,
                                              this.uniqueId,
                                              this.amount,
                                              this.priceDiff,
                                              this.name,
                                              this.translations);
  }

  private static getActiveTranslation(langCode2: string, translations: RestaurantMenuItemModifierTranslationDto[]): RestaurantMenuItemModifierTranslationDto | null {
    const activeTranslationIdx = translations.findIndex(x => x.languageCode2.toLowerCase() == langCode2.toLowerCase());
    const activeTranslation = activeTranslationIdx < 0 ? null : translations[activeTranslationIdx];

    return activeTranslation;
  }

  public static from(activeLangCode2: string, source: MenuItemModifier, currency: Currency): OrderableMenuItemModifierModel {

    const priceDiff: PriceDef = {
      currency,
      value: source.priceDiff
    };

    const amount: ProductQuantityDef | null = source.amount == null || source.measureUnit == null
                                              ? null
                                              : {
        amount: source.amount!,
        measureUnit: source.measureUnit!
      };

    return new OrderableMenuItemModifierModel(activeLangCode2,
                                              source.uniqueId,
                                              amount,
                                              priceDiff,
                                              source.name,
                                              source.translations);
  }
}

import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {BLOCK_WINDOW_CONTEXT_KEY} from '@orderly/shared';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {OrderableMenuItemModel, selectMenuFeature, selectMenuFeatureTable, TokenAndActions} from '@orderly/shared-menu-store';
import { exhaustMap, filter, first, map, Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import GetMenuForSelfServiceKioskResponse = Orderly.RestaurantWeb.Api.Messages.Public.GetMenuForSelfServiceKioskResponse;
import GetMenuForSelfServiceKioskResponseStatusDef = Orderly.RestaurantWeb.Api.Messages.Public.GetMenuForSelfServiceKioskResponse.StatusDef;
import TableClientRequestTypeDef = Orderly.Common.Enums.TableClientRequestTypeDef;
import RequestActionResponse = Orderly.RestaurantWeb.Api.Messages.Public.RequestActionResponse;
import CreateOrderUsingSelfServiceKioskRequest = Orderly.RestaurantWeb.Api.Messages.Public.CreateOrderUsingSelfServiceKioskRequest;
import MenuItem = Orderly.RestaurantWeb.Api.Messages.Public.CreateOrderRequestBase.MenuItem;
import Modifier = Orderly.RestaurantWeb.Api.Messages.Public.CreateOrderRequestBase.MenuItem.Modifier;
import CreateOrderResponse = Orderly.RestaurantWeb.Api.Messages.Public.CreateOrderResponse;

type LoadSettingsAndMenuResult = Omit<GetMenuForSelfServiceKioskResponse, 'status'>;

@Injectable({
              providedIn: 'root'
            })
export class KioskService {

  constructor(private readonly httpClient: HttpClient,
              private readonly store: Store<any>,) {
  }

  public loadSettingsAndMenu(deviceId: string): Observable<LoadSettingsAndMenuResult> {
    const url = `${environment.baseApiUrlWithTrailingSlash}v1.0/menu/by-kiosk-token/${deviceId}`;

    return this.httpClient
               .get<GetMenuForSelfServiceKioskResponse>(url, {
                 context: new HttpContext().set(BLOCK_WINDOW_CONTEXT_KEY, true)
               })
               .pipe(
                 map((response) => {

                   switch (response.status) {
                     case GetMenuForSelfServiceKioskResponseStatusDef.Success:

                       return response;

                     default:
                       throw new Error('Failed to get kiosk settings and menu');
                   }

                 }),
               );
  }

  public callWaiter(deviceId: string): Observable<RequestActionResponse> {
    const url: string = `${environment.baseApiUrlWithTrailingSlash}v1.0/kiosk/${deviceId}/table-action-request/request/${TableClientRequestTypeDef.CallWaiter}`;

    return this.httpClient.post<RequestActionResponse>(url, {});
  }

  public submitOrder(items: OrderableMenuItemModel[]): Observable<CreateOrderResponse> {
    const url = `${environment.baseApiUrlWithTrailingSlash}v1.0/order/from-kiosk/submit`;

    const tableState$ = this.store
                            .select(selectMenuFeatureTable)
                            .pipe(
                              first(),
                              filter(x => x.tokenAndActions != null),
                              map(x => x.tokenAndActions!)
                            );

    return tableState$.pipe(
      map((x: TokenAndActions) => {
        const requestItems = KioskService.convertMenuItems(items);
        const request: CreateOrderUsingSelfServiceKioskRequest = {
          apiCallToken: x.apiCallToken,
          items: requestItems
        };

        return request;
      }),
      exhaustMap((request: CreateOrderUsingSelfServiceKioskRequest) => {
        return this.httpClient.post<CreateOrderResponse>(url, request);
      }),
    );
  }

  private static convertMenuItems(orderedItems: OrderableMenuItemModel[]): MenuItem[] {

    const items: MenuItem[] = orderedItems.map(item => {

      const modifiers = item.orderedModifierGroups
                            .map(group => {
                              const groupModifiers = group.orderedModifiers.map(mod => {
                                const rs: Modifier = {
                                  groupUniqueId: group.uniqueIdLowercase,
                                  uniqueId: mod.uniqueId,
                                  quantity: 1,
                                };

                                return rs;
                              });

                              return groupModifiers;
                            })
                            .reduce((prev, curr) => {
                              return [...prev, ...curr];
                            }, []);


      const result: MenuItem = {
        categoryExternalId: item.parentMenuCategoryId,
        externalId: item.externalId,
        quantity: item.orderedMenuItemCount,
        sizeExternalId: item.orderedSize == null ? null : item.orderedSize.externalId,
        modifiers: modifiers
      };

      return result;
    });

    return items;
  }
}

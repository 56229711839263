import {createAction, props} from '@ngrx/store';

export interface RemoveCartMenuItemActionPayload {
  itemId: string;
}

export const removeCartMenuItemAction = createAction(
  '[CART] REMOVE',
  props<RemoveCartMenuItemActionPayload>()
);

import {createAction, props} from '@ngrx/store';
import {FailedRetry} from '../models/app-settings-state';

export type ReloadReasonType = 'NOT_LOADED' | 'OUTDATED';
export type ReloadReason = { reason: ReloadReasonType } | { reason: 'AFTER_FAIL', retry: FailedRetry };

export const reloadAppSettingsAction = createAction(
  '[APP_SETTINGS] RELOAD',
  props<ReloadReason>()
);

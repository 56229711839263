import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {AppRouteData, selectRouteData} from '@orderly/shared';
import {BaseComponent} from '@orderly/shared-components';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {
  requestNavigateToCartAction,
  requestNavigateToMenuAction,
  selectMenuFeatureTable,
  selectOrderedItems,
  selectOrdersEnabled
} from '@orderly/shared-menu-store';
import {DialogService} from 'primeng/dynamicdialog';
import {bufferWhen, exhaustMap, filter, interval, map, Observable, Subject, tap} from 'rxjs';
import {serviceZoneRoutePath} from '../../app-routing-constants';
import {CallWaiterComponent} from '../../modals/call-waiter/call-waiter.component';
import TableClientRequestTypeDef = Orderly.Common.Enums.TableClientRequestTypeDef;

@Component({
             selector: 'orderly-kiosk-right-menu',
             templateUrl: './right-menu.component.html',
             styleUrls: ['./right-menu.component.scss'],
             providers: [DialogService],
           })
export class RightMenuComponent extends BaseComponent {

  private readonly requestOpenServiceMenu$: Subject<void> = new Subject<void>();

  public readonly tableName$: Observable<string>;

  public readonly ordersEnabled$: Observable<boolean>;

  public readonly numberOfCartItems$: Observable<number>;

  public readonly callWaiterEnabled$: Observable<boolean>;

  public readonly isMenuNodeActive$ = this.store.select(selectRouteData).pipe(map(data => AppRouteData.isActive(data, 'menu')));

  public readonly isCartNodeActive$ = this.store.select(selectRouteData).pipe(map(data => AppRouteData.isActive(data, 'cart')));


  constructor(private readonly store: Store<any>,
              private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly dialogService: DialogService) {

    super();

    this.tableName$ = store.select(selectMenuFeatureTable).pipe(map(x => x.tokenAndActions?.tableName ?? ''));

    this.ordersEnabled$ = store.select(selectOrdersEnabled);

    this.numberOfCartItems$ = store.select(selectOrderedItems).pipe(map(x => x.length));

    this.callWaiterEnabled$ = store.select(selectMenuFeatureTable)
                                   .pipe(
                                     map(x => x.tokenAndActions?.actions),
                                     map(x => {
                                       if (x == null) {
                                         return false;
                                       }

                                       return x?.findIndex(a => a.type === TableClientRequestTypeDef.CallWaiter) > -1;
                                     })
                                   );

    const interval$ = interval(5000);

    this.registerForAutoDestroy(
      this.requestOpenServiceMenu$
          .pipe(
            bufferWhen(() => interval$),
            map(list => list.length),
            filter(length => length >= 10),
            tap(() => {
              console.log('service menu opened');
            }),
            exhaustMap(() => {
              return this.router.navigate([`/${serviceZoneRoutePath}`]);
            }),
          )
          .subscribe()
    );
  }

  public callWaiter(): void {
    this.dialogService
        .open(CallWaiterComponent,
              {
                closable: false,
                width: '70%',
                showHeader: false,
              });
  }

  public navigateToMenu(): void {
    this.store.dispatch(requestNavigateToMenuAction());
  }

  public openServiceMenu(): void {
    this.requestOpenServiceMenu$.next();
  }

  public navigateToCart(): void {
    this.store.dispatch(requestNavigateToCartAction());
  }
}

import {CurrencyPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {Orderly} from '@orderly/shared-menu-api-interfaces';

@Pipe({
        name: 'price',
        pure: true,
      })
export class PricePipe implements PipeTransform {


  constructor(@Inject(LOCALE_ID) private readonly _locale: string) {}


  transform(value: Orderly.Shared.Api.Messages.PriceDef): string | null {

    const currencyPipe = new CurrencyPipe(this._locale, value.currency.code3);

    return currencyPipe.transform(value.value, value.currency.code3, 'symbol-narrow', '1.2-2');;
  }
}

<div class="languages f32">

  <a *ngFor="let lang of supportedUiLanguages$ | async"
     [title]="lang.name"
     (click)="selectLanguage(lang)">

    <span [className]="'flag ' + lang.code2.toLowerCase()"></span>

    {{lang.name}}
  </a>

</div>

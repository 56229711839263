import {OverrideProperty} from '@orderly/shared';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import MenuItemSize = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemSize;

// TODO: rename to OrderableMenuItemSizeModel
export class OrderedMenuItemSizeModel
  implements OverrideProperty<Omit<MenuItemSize, 'amount' | 'sortingOrder'>, 'price', Orderly.Shared.Api.Messages.PriceDef> {

  public readonly quantity: Orderly.Shared.Api.Messages.ProductQuantityDef | null;

  public readonly price: Orderly.Shared.Api.Messages.PriceDef;


  constructor(amount: number | null,
              measureUnit: Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.MeasureUnit | null,
              public readonly externalId: string,
              public readonly name: string,
              price: number,
              currency: Orderly.Shared.Api.Messages.StaticData.GetAllStaticDataResponse.Currency,
              public readonly sortingOrder: number) {

    this.quantity = amount != null && measureUnit != null
                    ? {
        amount,
        measureUnit
      }
                    : null;

    this.price = {currency, value: price};
  }

}

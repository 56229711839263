import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  addCartMenuItemAction,
  markCurrentOrderAsCompletedAction, registerMenuItemViewAction,
  removeCartMenuItemAction,
  submitOrderAction,
} from '@orderly/shared-menu-store';
import {tap} from 'rxjs';
import {delayCleanupAfterNoInteractionFromUserAction} from '../../../../../../libs/shared-menu-store/src/lib/actions/delay-cleanup-after-no-interaction-from-user.action';
import {AutocleanupService} from '../../services/autocleanup.service';


@Injectable()
export class CartLastChangeTrackerEffects {

  constructor(private readonly actions$: Actions,
              private readonly trackingService: AutocleanupService) {
  }

  markCurrentOrderAsCompleted$ = createEffect(() => this.actions$.pipe(
                                                ofType(markCurrentOrderAsCompletedAction.type),
                                                tap(() => {

                                                  this.trackingService.cancelCleanup();
                                                })
                                              ), {dispatch: false}
  );

  addCartMenuItem$ = createEffect(() => this.actions$.pipe(
                                    ofType(addCartMenuItemAction.type),
                                    tap(() => {

                                      this.trackingService.registerCartChange();
                                    })
                                  ), {dispatch: false}
  );

  removeCartMenuItem$ = createEffect(() => this.actions$.pipe(
                                       ofType(removeCartMenuItemAction.type),
                                       tap(() => {
                                         this.trackingService.registerCartChange();
                                       })
                                     ), {dispatch: false}
  );

  submitOrder$ = createEffect(() => this.actions$.pipe(
                                ofType(submitOrderAction.type),
                                tap(() => {
                                  this.trackingService.registerCartChange();
                                })
                              ), {dispatch: false}
  );

  delayCleanupAfterNoInteractionFromUser$ = createEffect(() => this.actions$.pipe(
                                                           ofType(delayCleanupAfterNoInteractionFromUserAction.type),
                                                           tap(() => {
                                                             this.trackingService.delayCleanup();
                                                           })
                                                         ), {dispatch: false}
  );

  registerMenuItemView$ = createEffect(() => this.actions$.pipe(
                                         ofType(registerMenuItemViewAction.type),
                                         tap(() => {
                                           this.trackingService.delayCleanup();
                                         })
                                       ), {dispatch: false}
  );
}

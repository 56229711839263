import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {TableStateEffects} from './effects/table-state-effects';
import {MenuAppState} from './models/menu-app-state';
import {appSettingsReducer, initialState as appSettingsInitialState} from './reducers/app-settings-reducers';
import {fingerprintReducer} from './reducers/fingerprint-reducers';
import {tableReducer} from './reducers/table-reducers';
import {menuFeatureName} from './store-constants';

export const reducers: ActionReducerMap<MenuAppState> = {

  appSettings: appSettingsReducer,
  clientFingerprint: fingerprintReducer,
  table: tableReducer,

};

@NgModule({
            imports: [
              CommonModule,

              EffectsModule.forFeature([TableStateEffects]),

              StoreModule.forFeature(menuFeatureName, reducers, {
                initialState: {appSettings: appSettingsInitialState}
              }),
            ],
          })
export class SharedMenuStoreModule {}

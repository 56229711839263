import {createAction, props} from '@ngrx/store';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {Dayjs} from 'dayjs';

export interface UpdateTableStateActionPayload {
  menuButtonAction: Orderly.Common.Enums.QrMenuActionTypeDef;
  apiCallToken: string;
  apiCallTokenValidUntilUtc: Dayjs;
  actions: Orderly.RestaurantWeb.Api.Messages.Public.GetTableGuestActionsResponse.GuestActionDefinition[];
  menu: Orderly.RestaurantWeb.Api.Messages.Public.Models.RestaurantMenu;
  tableToken: string;
  tableName: string;
  ordersEnabled: boolean;
}

export const updateTableStateAction = createAction(
  '[TABLE] UPDATE',
  props<UpdateTableStateActionPayload>()
);

<nav class="nav">
  <div class="nav__item table-number"
       (click)="openServiceMenu()">
    {{tableName$ | async}}
  </div>

  <orderly-kiosk-language-selector class="nav__item menu"></orderly-kiosk-language-selector>

  <button class="nav__item menu"
          [class.active]="isMenuNodeActive$ | async"
          (click)="navigateToMenu()">

          <span class="nav__item-img-box">
            <i class="icon-food"></i>
          </span>
    <span class="nav__item-text">{{'Menu' | translate}}</span>
  </button>

  <button class="nav__item cart"
          *ngIf="(ordersEnabled$ | async) === true"
          [class.active]="isCartNodeActive$ | async"
          (click)="navigateToCart()">

          <span class="nav__item-img-box with-quantity-icon">
            <i class="icon-basket"></i>

            <span class="quantity" *ngIf="numberOfCartItems$ | async as numberOfCartItems">{{numberOfCartItems}}</span>
          </span>
    <span class="nav__item-text">{{'Cart' | translate}}</span>

  </button>

  <div class="nav__item waiter"
       *ngIf="(callWaiterEnabled$ | async) === true"
       (click)="callWaiter()">

          <span class="nav__item-img-box">
            <i class="icon-bell"></i>
          </span>
    <span class="nav__item-text">{{'Waiter' | translate }}</span>
  </div>
</nav>

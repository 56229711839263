import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {OrderableMenuItemModel} from '@orderly/shared-menu-store';
import MenuCategory = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuCategory;
import MenuItemCategoryTranslationDto = Orderly.RestaurantWeb.DataTransferObjects.Translations.MenuItemCategoryTranslationDto;
import MenuItemGroupsListStyleDef = Orderly.Common.Enums.MenuItemGroupsListStyleDef;

export class MenuCategoryModel {

  private readonly activeTranslation: MenuItemCategoryTranslationDto | null;

  public get name(): string {
    return this.activeTranslation?.name || this.defaultName;
  }

  public get description(): string | null {
    return this.activeTranslation?.description || this.defaultDescription;
  }

  public readonly items: OrderableMenuItemModel[];

  constructor(activeLangCode2: string,
              public readonly externalId: string,
              public readonly displayType: MenuItemGroupsListStyleDef,
              public readonly displaySortOrder: number,
              public readonly itemsThumbVisibility: Orderly.Common.Enums.MenuCategoryItemsThumbVisibilityDef,
              public readonly thumb: Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemImageThumb | null,
              public readonly images: Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemImage[],
              items: Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItem[],
              private readonly defaultName: string,
              private readonly defaultDescription: string | null,
              translations: MenuItemCategoryTranslationDto[]) {

    const activeTranslationIdx = translations.findIndex(x => x.languageCode2.toLowerCase() == activeLangCode2.toLowerCase());
    this.activeTranslation = activeTranslationIdx < 0 ? null : translations[activeTranslationIdx];

    this.items = items.map(x => OrderableMenuItemModel.from(activeLangCode2, externalId, x, itemsThumbVisibility));
  }

  public static from(activeLangCode2: string,
                     source: MenuCategory,
                     displayType: MenuItemGroupsListStyleDef): MenuCategoryModel {

    return new MenuCategoryModel(activeLangCode2,
                                 source.externalId,
                                 displayType,
                                 source.displaySortOrder,
                                 source.itemsThumbVisibility,
                                 source.thumb,
                                 source.images,
                                 source.items,
                                 source.name,
                                 source.description,
                                 source.translations);
  }
}

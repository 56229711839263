import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {environment} from '../../../public-web-qr/src/environments/environment';


import enLanguage from './../assets/i18n/en.json';
import deLanguage from './../assets/i18n/de.json';
import ukLanguage from './../assets/i18n/uk.json';
import itLanguage from './../assets/i18n/it.json';
import plLanguage from './../assets/i18n/pl.json';
import {AutocleanupService} from './services/autocleanup.service';


@Component({
             selector: 'orderly-kiosk-root',
             templateUrl: './app.component.html',
             styleUrls: ['./app.component.scss'],
           })
export class AppComponent {

  public readonly showCleanupConfirmationDialog$: Observable<boolean>;

  constructor(translateService: TranslateService,
              cartAutocleanupService: AutocleanupService) {

    this.showCleanupConfirmationDialog$ = cartAutocleanupService.showCleanupConfirmationDialog$;

    translateService.setTranslation('en', enLanguage);
    translateService.setTranslation('de', deLanguage);
    translateService.setTranslation('uk', ukLanguage);
    translateService.setTranslation('it', itLanguage);
    translateService.setTranslation('pl', plLanguage);

    translateService.setDefaultLang('en');
    translateService.use('en');

    if (!environment.production) {

      if (environment.test) {
        console.log('This is test build');
      } else {
        console.log('This is development build');
      }
    }
  }

}

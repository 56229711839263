import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {BaseComponent} from '@orderly/shared-components';
import {selectMenuFeatureTable} from '@orderly/shared-menu-store';
import {CountdownConfig, CountdownEvent} from 'ngx-countdown';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import { catchError, combineLatest, delay, exhaustMap, filter, map, tap, throwError, timer } from 'rxjs';
import {KioskService} from '../../services/kiosk.service';

@Component({
             selector: 'orderly-kiosk-call-waiter',
             templateUrl: './call-waiter.component.html',
             styleUrls: ['./call-waiter.component.scss']
           })
export class CallWaiterComponent extends BaseComponent {

  public readonly hideCancelButtonCountdownCfg: CountdownConfig = {
    demand: false,
    format: 'mm:ss',
    leftTime: 3,
  };

  public isCancelButtonVisible = true;

  public requestSent = false;


  constructor(private readonly ref: DynamicDialogRef,
              private readonly kioskService: KioskService,
              private readonly store: Store<any>) {
    super();

    const deviceId$ = store.select(selectMenuFeatureTable)
                           .pipe(
                             map(x => x.tokenAndActions?.apiCallToken),
                             filter(x => x != null),
                             map(x => x!),
                           );

    this.registerForAutoDestroy(
      combineLatest([deviceId$, timer(4000)])
        .pipe(
          exhaustMap((x) => {
            return this.kioskService.callWaiter(x[0]);
          }),
          delay(10000),
          tap(() => {
            this.requestSent = true;
          }),
          delay(3000),
          tap(() => {
            this.ref.close();
          }),
          catchError((err) => {
            this.ref.close();

            // TODO: log error
            return throwError(err);
          })
        )
        .subscribe()
    );
  }

  public cancel(): void {
    this.ref.close();
  }

  public hideCancelButton($event: CountdownEvent): void {
    if ($event.action === 'done') {
      this.isCancelButtonVisible = false;
    }
  }
}

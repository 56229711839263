import {createAction, props} from '@ngrx/store';
import {OrderableMenuItemModel} from '@orderly/shared-menu-store';

export interface AddCartMenuItemActionPayload {
  item: OrderableMenuItemModel;
}

export const addCartMenuItemAction = createAction(
  '[CART] ADD',
  props<AddCartMenuItemActionPayload>()
);

import {createAction, props} from '@ngrx/store';

export interface RegisterMenuItemViewActionPayload {
  itemId: string;
}

export const registerMenuItemViewAction = createAction(
  '[MENU] REGISTER_MENU_ITEM_VIEW',
  props<RegisterMenuItemViewActionPayload>()
);

<div class="options__row-box">
  <div class="options-group-header">
    <h3 class="options__name">{{group.name}}</h3>

    <ng-container *ngIf="readonly === false">
      <div class="options__tag options__tag--optional" *ngIf="isRequired === false">{{'Optional' | translate}}</div>
      <div class="options__tag options__tag--required" *ngIf="isRequired === true">{{'Required' | translate}}</div>
    </ng-container>
  </div>

  <p class="options__instruction-text" *ngIf="readonly === false">{{instructionText$ | async}}</p>

  <form>

    <ul class="options-list">
      <li class="options-list__item"
          *ngIf="readonly === false && group.selectionMode === 'zero-or-one'">

        <label class="custom-checkbox">
          <input type="radio"
                 class="custom-checkbox__input"
                 [name]="group.uniqueIdLowercase"
                 [checked]="true"
                 (change)="modifierSelected(null, $event)">

          <span class="custom-checkbox__control"></span>
          <span class="custom-checkbox__text">
            <span class="custom-checkbox__label-text">{{'None' | translate}}</span>
          </span>
        </label>

      </li>

      <li class="options-list__item"
          *ngFor="let item of group.orderedModifiers; let i = index">

        <label class="custom-checkbox">

          <ng-container *ngIf="readonly === false">
            <input type="checkbox"
                   class="custom-checkbox__input"
                   (change)="modifierSelected(item, $event)"
                   *ngIf="group.selectionMode === 'at-least-one' || group.selectionMode === 'zero-or-more'">

            <input type="radio"
                   class="custom-checkbox__input"
                   [name]="group.uniqueIdLowercase"
                   (change)="modifierSelected(item, $event)"
                   *ngIf="group.selectionMode === 'exactly-one' || group.selectionMode === 'zero-or-one'">

            <span class="custom-checkbox__control"></span>
          </ng-container>

          <span class="custom-checkbox__text">
            <span class="custom-checkbox__label-text">{{item.name}}</span>
            <span class="custom-checkbox__modifier-price" *ngIf="item.priceDiff != null && item.priceDiff.value > 0">{{item.priceDiff | price}}</span>
          </span>
        </label>

      </li>
    </ul>

  </form>
</div>

<div class="page-wrapper d-flex">
  <div class="content-box">
    <main>
      <div class="component-wrapper">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>

  <orderly-kiosk-right-menu></orderly-kiosk-right-menu>

</div>

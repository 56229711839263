import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CountdownComponent, CountdownConfig, CountdownEvent} from 'ngx-countdown';
import {AutocleanupService, CLEANUP_MODAL_VISIBILITY_TIMEOUT_MS} from '../../services/autocleanup.service';

@Component({
  selector: 'orderly-kiosk-autocleanup-confirmation',
  templateUrl: './autocleanup-confirmation.component.html',
  styleUrls: ['./autocleanup-confirmation.component.scss']
})
export class AutocleanupConfirmationComponent implements AfterViewInit {

  public readonly countdownConfig: CountdownConfig = {
    demand: true,
    format: 'mm:ss',
    leftTime: CLEANUP_MODAL_VISIBILITY_TIMEOUT_MS / 1000,
  };

  @ViewChild('cd', { static: false })
  // @ts-ignore
  private countdownComponent: CountdownComponent;


  constructor(private readonly autocleanupService: AutocleanupService) {
  }


  ngAfterViewInit(): void {
    this.countdownComponent.begin();
  }

  public keepItems(): void {
    this.autocleanupService.delayCleanup();
  }

  public clearCart(): void {
    this.countdownComponent.stop();
    this.autocleanupService.clearCart();
  }

  public handleCountdownEvent($event: CountdownEvent): void {
    if ($event.action === 'done') {
      this.autocleanupService.clearCart();
    }
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {getTranslateModuleConfig, SharedModule} from '@orderly/shared';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {ImageModule} from 'primeng/image';
import {RippleModule} from 'primeng/ripple';
import {CategoriesListComponent} from './components/categories-list/categories-list.component';
import {SharedClientMenuRoutingModule} from './shared-client-menu-routing.module';
import {ItemsListComponent} from './components/items-list/items-list.component';
import {
  ItemQuantityInputComponent
} from './components/items-list/item-details/item-quantity-input/item-quantity-input.component';
import {ModifiersGroupComponent} from './components/items-list/item-details/modifiers-group/modifiers-group.component';
import {ItemDetailsComponent} from './components/items-list/item-details/item-details.component';
import {
  ItemSizesSelectorComponent
} from './components/items-list/item-details/item-sizes-selector/item-sizes-selector.component';

@NgModule({
            imports: [
              CommonModule,
              SharedClientMenuRoutingModule,
              DialogModule,
              ButtonModule,
              TranslateModule.forChild(getTranslateModuleConfig(false)),
              ImageModule,
              RippleModule,
              SharedModule,
            ],
            declarations: [
              CategoriesListComponent,
              ItemsListComponent,
              ItemQuantityInputComponent,
              ModifiersGroupComponent,
              ItemDetailsComponent,
              ItemSizesSelectorComponent
            ],
            exports: [
              SharedClientMenuRoutingModule,
            ]
          })
export class SharedClientMenuModule {
}

import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {Dayjs} from 'dayjs';
import {OrderingCartState} from './ordering-cart-state';

export interface TokenAndActions {
  menuButtonAction: Orderly.Common.Enums.QrMenuActionTypeDef;
  apiCallToken: string;
  apiCallTokenValidUntilUtc: Dayjs;
  actions: Orderly.RestaurantWeb.Api.Messages.Public.GetTableGuestActionsResponse.GuestActionDefinition[];
  tableToken: string;
  tableName: string;
  ordersEnabled: boolean;
}


export interface TableState {
  tokenAndActions: TokenAndActions | null;
  menu: Orderly.RestaurantWeb.Api.Messages.Public.Models.RestaurantMenu | null;
  cart: OrderingCartState;
}

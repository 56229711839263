import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrderedMenuItemSizeModel} from '@orderly/shared-menu-store';

@Component({
  selector: 'orderly-item-sizes-selector',
  templateUrl: './item-sizes-selector.component.html',
  styleUrls: ['./item-sizes-selector.component.scss']
})
export class ItemSizesSelectorComponent implements OnInit {

  @Input()
  public sizes: ReadonlyArray<OrderedMenuItemSizeModel> = [];

  @Input()
  public currencyCode3!: string;

  @Input()
  public model: OrderedMenuItemSizeModel | null = null;

  @Input()
  public readonly: boolean = false;


  @Output()
  public sizeChanged = new EventEmitter<OrderedMenuItemSizeModel>();


  public orderedSizes: ReadonlyArray<OrderedMenuItemSizeModel> = [];


  public sizeSelected(size: OrderedMenuItemSizeModel, $event: Event) {
    this.sizeChanged.emit(size);
  }


  ngOnInit(): void {
    this.orderedSizes = [...this.sizes].sort((a, b) => {
      if (a.sortingOrder === b.sortingOrder) {
        return 0;
      }

      return a.sortingOrder > b.sortingOrder ? 1 : -1;
    });
  }
}

import {Data} from '@angular/router';
import {nameof} from 'ts-simple-nameof';

export type RouteType = 'menu' | 'cart' | 'qr-actions-list';

export class AppRouteData {
  constructor(public readonly routeType: RouteType) {
  }


  public static isActive(routeData: Data, type: RouteType): boolean {
    if (routeData) {
      const routeType = routeData[nameof<AppRouteData>(x => x.routeType)]

      return routeType === type;
    }

    return false;
  }
}

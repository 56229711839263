<div class="options__row-box">
  <div class="options-group-header">
    <h3 class="options__name">{{'Size' | translate}}</h3>
  </div>

  <form>

    <ul class="options-list">
      <li class="options-list__item"
          [class.with-quantity]="size.quantity != null"
          *ngFor="let size of orderedSizes; let i = index">

        <label class="custom-checkbox">

          <ng-container *ngIf="readonly === false">
            <input type="radio"
                   class="custom-checkbox__input"
                   name="menu-item-sizes"
                   (change)="sizeSelected(size, $event)">

            <span class="custom-checkbox__control"></span>
          </ng-container>

          <span class="custom-checkbox__text">
            <span class="custom-checkbox__label-text">
              {{size.name}}

              <ng-container *ngIf="size.quantity != null">
                <br/>
                {{size.quantity | weight}}
              </ng-container>
            </span>
            <span class="custom-checkbox__modifier-price">{{size.price | price}}</span>
          </span>
        </label>

      </li>
    </ul>

  </form>
</div>

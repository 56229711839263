import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'orderly-item-quantity-input',
  templateUrl: './item-quantity-input.component.html',
  styleUrls: ['./item-quantity-input.component.scss']
})
export class ItemQuantityInputComponent {

  @Input()
  public quantity: number = 1;

  @Output()
  public readonly selectionChange = new EventEmitter<number>();

  public get isSubstractDisabled() {
    return this.quantity <= 1;
  }

  public substract(): void {
    if (this.quantity > 1) {
      this.quantity = this.quantity - 1;
    }

    this.selectionChange.next(this.quantity);
  }

  public add(): void {
    this.quantity = this.quantity + 1;

    this.selectionChange.next(this.quantity);
  }
}

import {
  MissingTranslationHandler,
  TranslateModuleConfig
} from '@ngx-translate/core';
import {TodoLabeledMissingTranslationHandler} from './todo-labeled-missing-translation.handler';

export function getTranslateModuleConfig(isDevOrTest: boolean): TranslateModuleConfig {
  const result: TranslateModuleConfig = {
    defaultLanguage: 'en',
    isolate: false,
    extend: true,
    useDefaultLang: false,
  };

  if (isDevOrTest) {
    result.missingTranslationHandler = {
      provide: MissingTranslationHandler,
      useClass: TodoLabeledMissingTranslationHandler,
    };
  }

  return result;
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NeverError} from '@orderly/shared';
import {OrderableMenuItemModifierGroupModel, OrderableMenuItemModifierModel} from '@orderly/shared-menu-store';
import {EMPTY, Observable} from 'rxjs';
import {ModifierGroupSelectionChangedEventPayload} from './models/modifier-group-selection-changed-event-payload';

@Component({
             selector: 'orderly-modifiers-group',
             templateUrl: './modifiers-group.component.html',
             styleUrls: ['./modifiers-group.component.scss']
           })
export class ModifiersGroupComponent implements OnInit {

  private selectedModifiers: Array<OrderableMenuItemModifierModel> = new Array<OrderableMenuItemModifierModel>();

  public instructionText$: Observable<string> = EMPTY;

  @Input()
  public group!: OrderableMenuItemModifierGroupModel;

  @Input()
  public currencyCode3!: string;

  @Input()
  public readonly: boolean = false;

  @Output()
  public selectionChanged = new EventEmitter<ModifierGroupSelectionChangedEventPayload>();


  public get isRequired(): boolean {
    if (this.group != null) {
      if (this.group.minAllowedAmount > 0) {
        return true;
      }
    }

    return false;
  }


  constructor(private readonly translateService: TranslateService) {

  }

  ngOnInit(): void {

    const selectionModeDescription = this.getSelectionModeDescription();
    const selectionModeDescriptionParams = {
      minAllowedAmount: this.group.minAllowedAmount,
      maxAllowedAmount: this.group.maxAllowedAmount
    };

    this.instructionText$ = this.translateService.get(selectionModeDescription, selectionModeDescriptionParams);
  }


  private getSelectionModeDescription(): string {

    switch (this.group.selectionMode) {

      case 'exactly-one':
        return 'Select exactly one';
      case 'at-least-one':
        return this.group.maxAllowedAmount == null ? 'Select at least one'
                                                   : 'Select between {{ minAllowedAmount }} and {{ maxAllowedAmount }}';
      case 'zero-or-one':
        return 'Select maximum one';
      case 'zero-or-more':
        return this.group.maxAllowedAmount == null ? 'Select any' : 'Select any but maximum {{ maxAllowedAmount }}';

      default:
        throw new NeverError(this.group.selectionMode);
    }
  }

  public modifierSelected(item: OrderableMenuItemModifierModel | null, $event: Event): void {

    switch (this.group.selectionMode) {
      case 'exactly-one':
      case 'zero-or-one':
        this.selectedModifiers.splice(0);

        if (item != null) {
          this.selectedModifiers.push(item);
        }
        break;

      case 'zero-or-more':
      case 'at-least-one':

        const modifierIdx = this.selectedModifiers.indexOf(item!);

        if (modifierIdx === -1) {
          this.selectedModifiers.push(item!);
        } else {
          this.selectedModifiers.splice(modifierIdx, 1);
        }

        break;

      default:
        throw new NeverError(this.group.selectionMode);
    }

    this.selectionChanged.next(new ModifierGroupSelectionChangedEventPayload(this.group, this.selectedModifiers));
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Data, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {defaultIfEmpty, map, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {selectMenuFeatureTable, TableState} from '@orderly/shared-menu-store';
import dayjs from 'dayjs';
import {RouteDataForRedirect} from './route-data-for-redirect';


const RequireApiCallTokenGuardRouteData = 'RequireApiCallTokenGuardRouteData';

@Injectable({
              providedIn: 'root'
            })
export class RequireApiCallTokenGuard implements CanActivate {

  public static getRouteDataForRedirect(redirectTo: string): Data {

    const data: RouteDataForRedirect = {
      redirectTo
    };

    const result = {};

    // @ts-ignore
    result[RequireApiCallTokenGuardRouteData] = data;

    return result;
  }


  constructor(private readonly store: Store<any>,
              private readonly router: Router,
              private readonly logger: NGXLogger) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const table$ = this.store.select(selectMenuFeatureTable);
    const redirectData: RouteDataForRedirect | null = route.data[RequireApiCallTokenGuardRouteData];
    const redirectToUrlTree = redirectData == null ? null : this.router.parseUrl(redirectData.redirectTo);
    const falseResult = redirectToUrlTree == null ? false : redirectToUrlTree;

    return table$.pipe(
      map((state: TableState) => {

        if (state.tokenAndActions == null) {

          this.logger.debug(`RequireApiCallTokenGuard: not allowed because tokenAndActions is null. Url: ${routerState.url}`);

          return falseResult;
        }

        const result: boolean = state.tokenAndActions.apiCallTokenValidUntilUtc.isAfter(dayjs());

        this.logger.debug(`RequireApiCallTokenGuard: result is [${result}]. Url: ${routerState.url}`);

        return result ? true : falseResult;
      }),
      defaultIfEmpty(falseResult),
    );
  }

}

<div class="categories" *ngIf="categoryWithItems$ | async as category">
  <header class="header">
    <a class="back-link"
       (click)="backToCategoriesList()"
       *ngIf="showBackButton">

      <i class="icon-left"></i>
    </a>
    <h2 class="header__title">{{category.name}}</h2>
  </header>

  <div class="categories__item-details">
    <ul class="dish">
      <li class="dish__item"
          *ngFor="let item of category.items; trackBy: itemTrackBy"
           (click)="showDetails(item)">

        <div class="left-side">
          <p class="dish__title">{{item.name}}</p>
          <p class="dish__ingredients" *ngIf="item.ingredientsListAsText.length > 0">{{item.ingredientsListAsText}}</p>

          <div class="dish__item-price-weight">
            <span class="dish__price" *ngIf="item.sizes.length == 0">{{item.price | price}}</span>
            <span class="dish__price" *ngIf="item.sizes.length > 0">{{'From' | translate}} {{item.lowestPrice | price}}</span>
            <span class="dish__weight" *ngIf="item.quantity != null && item.sizes.length == 0">{{item.quantity | weight}}</span>
          </div>
        </div>

        <div class="right-side" *ngIf="item.thumb != null">
          <div class="img-box">
            <img [src]="item.thumb.url" [alt]="item.name">
          </div>
        </div>

      </li>
    </ul>
  </div>
</div>


<ng-container *ngIf="selectedMenuItem$ | async as selectedMenuItem">

  <p-dialog position="bottom"
            [visible]="selectedMenuItem != null"
            [modal]="true"
            [closable]="false"
            [blockScroll]="true"
            [draggable]="false"
            [dismissableMask]="true"
            [breakpoints]="{'640px': '100vw'}"
            [style]="{width: '80vw'}"
            [maskStyleClass]="(selectedMenuItemHasModifiers$ | async) ? 'with-modifiers' : 'no-modifiers'"
            [contentStyleClass]="'styled-modal-scrollbar'">

    <ng-template pTemplate="header">

      <div class="items-list-header">
        <h3 class="dish__name">{{selectedMenuItem.name}}</h3>

        <a (click)="hideMenuItemDetails()"
           class="close-btn">
          <i class="icon-cancel"></i>
        </a>
      </div>
    </ng-template>

    <div class="modal">
      <orderly-item-details [isReadonly]="(isReadonly$ | async) === true"
                            [selectedMenuItem]="selectedMenuItem"
                            (addToCart)="addToCart($event)"
                            class="dish__details">
      </orderly-item-details>

      <div class="dish__actions"
           *ngIf="(isReadonly$ | async) === false">

        <button class="items-list-add-to-cart"
                [attr.disabled]="selectedMenuItem.isValid === false ? 'disabled' : null"
                [class.disabled]="selectedMenuItem.isValid === false"
                (click)="addToCart(selectedMenuItem)">

          {{'Add to cart' | translate}}
          <span *ngIf="selectedMenuItem.totalPrice != null">{{selectedMenuItem.totalPrice | price}}</span>

        </button>
      </div>
    </div>

  </p-dialog>

</ng-container>

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {
  updateTableStateAction,
  UpdateTableStateActionPayload
} from '@orderly/shared-menu-store';
import {map} from 'rxjs';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {
  updateAppSettingsLocalizationAction,
  updateSupportedLanguagesActionPayload
} from '../actions/update-app-settings-localization-action';

import Language2 = Orderly.Shared.Api.Messages.Language2;


@Injectable()
export class TableStateEffects {

  constructor(private readonly actions$: Actions,
              private readonly translateService: TranslateService) {
  }

  updateTableState$ = createEffect(() => this.actions$.pipe(
                                     ofType(updateTableStateAction.type),
                                     map((x: UpdateTableStateActionPayload) => {

                                       const availableAppTranslationLangCodes: string[] = this.translateService
                                                                                              .langs
                                                                                              .map(
                                                                                                code2 => code2.toLowerCase()
                                                                                              );
                                       const languages: Language2[] = x.menu
                                                                       .languages
                                                                       .filter((l) => {
                                                                         return availableAppTranslationLangCodes.indexOf(l.code.toLowerCase()) >= 0;
                                                                       })
                                                                       .map(l => {
                                                                         return {
                                                                           code2: l.code.toLowerCase(),
                                                                           name: l.name
                                                                         };
                                                                       });

                                       if (x.menu.languages.length !== languages.length) {
                                         console.log('Not all server languages are supported by the app');
                                       }

                                       if (languages.length === 0) {
                                         const serverLangCodes = x.menu.languages.map(l => l.code).join(',');
                                         const localLangCodes = this.translateService.langs.join(',');

                                         throw new Error(`Server and local app languages are not compatible. Server: ${serverLangCodes}. Local: ${localLangCodes}`);
                                       }

                                       const defaultLang2Lowercase: string = x.menu.defaultLanguage.code.toLowerCase();
                                       const defaultLanguageIndex = languages.findIndex(l => l.code2 === defaultLang2Lowercase);
                                       const englishLanguageIndex = languages.findIndex(l => l.code2 === 'en');
                                       const defaultLanguage: Language2 = defaultLanguageIndex >= 0
                                                                          ? languages[defaultLanguageIndex]
                                                                          : englishLanguageIndex >= 0
                                                                            ? languages[englishLanguageIndex]
                                                                            : languages[0];

                                       const payload: updateSupportedLanguagesActionPayload = {
                                         defaultLanguage,
                                         languages
                                       };

                                       return updateAppSettingsLocalizationAction(payload);
                                     }),
                                   )
  );

  updateAppSettingsLocalization$ = createEffect(() => this.actions$.pipe(
                                                  ofType(updateAppSettingsLocalizationAction.type),
                                                  map((x: updateSupportedLanguagesActionPayload) => {

                                                    const langCode2Lowercase = x.defaultLanguage.code2.toLowerCase();
                                                    const isMenuDefaultLanguageSupportedByTheApp = this.translateService.langs.indexOf(langCode2Lowercase) > -1

                                                    if (isMenuDefaultLanguageSupportedByTheApp) {
                                                      this.translateService.use(langCode2Lowercase);
                                                      this.translateService.setDefaultLang(langCode2Lowercase);
                                                    } else {
                                                      this.translateService.use('en');
                                                      this.translateService.setDefaultLang('en');
                                                    }
                                                  }),
                                                ), {dispatch: false}
  );

}

import {createReducer, on} from '@ngrx/store';
import {triggerCleanupAfterNoInteractionFromUserAction} from '../actions/trigger-cleanup-after-no-interaction-from-user-action';
import {updateTableStateAction, UpdateTableStateActionPayload} from '../actions/update-table-state-action';
import {activeLanguageChangedAction, ActiveLanguageChangedActionPayload} from '../actions/active-language-changed-action';
import {resetTableTokenAction} from '../actions/reset-table-token-action';
import {addCartMenuItemAction, AddCartMenuItemActionPayload} from '../actions/cart/add-cart-menu-item-action';
import {removeCartMenuItemAction, RemoveCartMenuItemActionPayload} from '../actions/cart/remove-cart-menu-item-action';
import {markCurrentOrderAsCompletedAction} from '../actions/cart/mark-current-order-as-completed-action';
import {TableState, TokenAndActions} from '../models/table-state';

export const initialState: TableState = {
  tokenAndActions: null,
  menu: null,
  cart: {
    orderedItems: [],
  }
};

export const tableReducer = createReducer(
  initialState,

  on(resetTableTokenAction, (state) => {
    return {...state, tokenAndActions: null};
  }),

  on(markCurrentOrderAsCompletedAction, (state) => {

    return {...state, cart: {orderedItems: []}};
  }),

  on(triggerCleanupAfterNoInteractionFromUserAction, (state) => {

    return {...state, cart: {orderedItems: []}};
  }),

  on(updateTableStateAction, (state, payload: UpdateTableStateActionPayload) => {

    const tokenAndActions: TokenAndActions = {
      actions: payload.actions,
      apiCallToken: payload.apiCallToken,
      apiCallTokenValidUntilUtc: payload.apiCallTokenValidUntilUtc,
      menuButtonAction: payload.menuButtonAction,
      tableToken: payload.tableToken,
      tableName: payload.tableName,
      ordersEnabled: payload.ordersEnabled
    };

    return {...state, tokenAndActions: tokenAndActions, menu: payload.menu,};
  }),

  on(addCartMenuItemAction, (state: TableState, payload: AddCartMenuItemActionPayload) => {
    const currentItems = [...state.cart.orderedItems];

    currentItems.push(payload.item);

    const newCart = {...state.cart, orderedItems: currentItems};

    return {...state, cart: newCart};
  }),

  on(removeCartMenuItemAction, (state: TableState, payload: RemoveCartMenuItemActionPayload) => {
    const currentItems = [...state.cart.orderedItems];
    const newItems = currentItems.filter(x => x.externalId !== payload.itemId);

    const newCart = {...state.cart, orderedItems: newItems};

    return {...state, cart: newCart};
  }),

  on(activeLanguageChangedAction, (state: TableState, payload: ActiveLanguageChangedActionPayload) => {

    const newCartItems = state.cart.orderedItems.map(x => x.cloneAndChangeActiveLanguage(payload.langCode2));
    const newCart = {...state.cart, orderedItems: newCartItems};

    return {...state, cart: newCart};
  }),
);

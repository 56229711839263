import {createAction, props} from '@ngrx/store';
import {OrderableMenuItemModel} from '@orderly/shared-menu-store';

export interface SubmitOrderActionPayload {
  items: OrderableMenuItemModel[];
}

export const submitOrderAction = createAction(
  '[CART] SUBMIT_ORDER',
  props<SubmitOrderActionPayload>()
);

import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {activeLanguageChangedAction, selectMenuFeatureAppSettings} from '@orderly/shared-menu-store';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {map, Observable} from 'rxjs';
import Language2 = Orderly.Shared.Api.Messages.Language2;

@Component({
             selector: 'orderly-kiosk-languages-list',
             templateUrl: './languages-list.component.html',
             styleUrls: ['./languages-list.component.scss']
           })
export class LanguagesListComponent {

  public readonly supportedUiLanguages$: Observable<Language2[]>;

  constructor(private readonly ref: DynamicDialogRef,
              private readonly store: Store<any>) {

    this.supportedUiLanguages$ = store.select(selectMenuFeatureAppSettings)
                                      .pipe(
                                        map(x => x.selectableLanguages),
                                      );
  }


  public selectLanguage(lang: Language2): void {

    this.store.dispatch(activeLanguageChangedAction({langCode2: lang.code2.toLowerCase()}));

    this.ref.close();
  }
}

import {createAction, props} from '@ngrx/store';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import Language2 = Orderly.Shared.Api.Messages.Language2;

export interface updateSupportedLanguagesActionPayload {
  defaultLanguage: Language2;
  languages: Language2[];
}

export const updateAppSettingsLocalizationAction = createAction(
  '[APP_SETTINGS] UPDATE SUPPORTED LANGUAGES',
  props<updateSupportedLanguagesActionPayload>()
);

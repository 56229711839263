import {createAction, props} from '@ngrx/store';

export type FailReasonType = 'NO_CONNECTION' | 'UNKNOWN';

export interface FailAppSettingsLoadActionPayload {
  reason: FailReasonType;
}

export const failAppSettingsLoadAction = createAction(
  '[APP_SETTINGS] FAIL_LOAD',
  props<FailAppSettingsLoadActionPayload>()
);

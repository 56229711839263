import {Orderly} from '@orderly/shared-menu-api-interfaces';
import MenuItemIngredient = Orderly.RestaurantWeb.Api.Messages.Public.Models.MenuItemIngredient;
import MenuItemIngredientTranslationDto = Orderly.RestaurantWeb.DataTransferObjects.Translations.MenuItemIngredientTranslationDto;

export class MenuItemIngredientModel implements Omit<MenuItemIngredient, 'translations'> {

  private _activeLangCode2: string;
  private _activeTranslation: MenuItemIngredientTranslationDto | null;

  public get name(): string {
    return this._activeTranslation?.name || this.defaultName;
  }


  constructor(activeLangCode2: string,
              public readonly allergens: Orderly.RestaurantWeb.Api.Messages.Public.Models.Allergen[],
              public readonly containsAlcohol: boolean,
              private readonly defaultName: string,
              private readonly translations: MenuItemIngredientTranslationDto[]) {

    this._activeLangCode2 = activeLangCode2;
    this._activeTranslation = MenuItemIngredientModel.getActiveTranslation(activeLangCode2, translations);
  }

  public changeActiveLanguage(langCode2: string): void {

    this._activeLangCode2 = langCode2;
    this._activeTranslation = MenuItemIngredientModel.getActiveTranslation(langCode2, this.translations);
  }


  private static getActiveTranslation(langCode2: string,
                                      translations: ReadonlyArray<MenuItemIngredientTranslationDto>) {

    const activeTranslationIdx = translations.findIndex(x => x.languageCode2.toLowerCase() == langCode2.toLowerCase());
    const activeTranslation = activeTranslationIdx < 0 ? null : translations[activeTranslationIdx];

    return activeTranslation;
  }
}

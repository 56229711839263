import {createReducer, on} from '@ngrx/store';
import {Orderly} from '@orderly/shared-menu-api-interfaces';
import {AppSettingsState, FailedRetry, resetTableTokenAction} from '@orderly/shared-menu-store';
import {updateAppSettingsAction} from '@orderly/shared-menu-store';
import {failAppSettingsLoadAction} from '@orderly/shared-menu-store';
import {updateAppSettingsLocalizationAction} from '../actions/update-app-settings-localization-action';
import Language2 = Orderly.Shared.Api.Messages.Language2;

export const initialState: AppSettingsState = {
  ordersEnabled: false,
  lastUpdatedOnUnixTime: null,
  fail: null,
  appUILanguages: [],
  selectableLanguages: [],
  defaultLanguage: null,
};

export const appSettingsReducer = createReducer(
  initialState,

  on(resetTableTokenAction, (state) => {

    const englishLangIndex = state.appUILanguages.findIndex(x => x.code2.toLowerCase() === 'en');
    const defaultLanguage: Language2 = englishLangIndex > -1
                                       ? state.appUILanguages[englishLangIndex]
                                       : state.appUILanguages[0];

    return {
      ...state,
      selectableLanguages: state.appUILanguages,
      defaultLanguage: defaultLanguage,
    };
  }),

  on(updateAppSettingsLocalizationAction, (state, payload) => {
    return {
      ...state,
      selectableLanguages: payload.languages,
      defaultLanguage: payload.defaultLanguage,
    };
  }),

  on(updateAppSettingsAction, (state, payload) => {

    return {
      ...state,
      lastUpdatedOnUnixTime: payload.loadedOnUnixTime,
      selectableLanguages: payload.uiLanguages,
      appUILanguages: payload.uiLanguages,
      fail: null
    };
  }),

  on(failAppSettingsLoadAction, (state) => {

    const failedState: FailedRetry = {
      count: state.fail?.count ?? 0,
      lastFailedOnUnixTime: new Date().getTime()
    };

    return {...state, fail: failedState};
  })
);

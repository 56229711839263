import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {v4 as uuidv4} from 'uuid';

export class ProgressDialogToken {
  constructor(public readonly id: string) {}
}


@Injectable({
  providedIn: 'root'
})
export class ModalProgressService implements OnDestroy {

  private readonly displayRequests$ = new BehaviorSubject<ProgressDialogToken[]>([]);

  public get isVisible$(): Observable<boolean> {
    return this.displayRequests$.pipe(
      map((x: ProgressDialogToken[]) => x.length > 0),
    );
  }

  public display(): ProgressDialogToken {
    const newReq: ProgressDialogToken = new ProgressDialogToken(uuidv4());
    const reqs = [...this.displayRequests$.value, newReq];

    this.displayRequests$.next(reqs);

    return newReq;
  }

  public hideAll(): void {
    this.displayRequests$.next([]);
  }

  public hide(token: ProgressDialogToken): void {
    const reqs = [...this.displayRequests$.value];
    const tokenIdx = reqs.findIndex((x) => x.id === token.id);

    if (tokenIdx >= 0) {
      reqs.splice(
        tokenIdx, 1,
      );
    }

    this.displayRequests$.next(reqs);
  }

  ngOnDestroy(): void {
    this.displayRequests$.complete();
  }

}

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {
  activeLanguageChangedAction,
  ActiveLanguageChangedActionPayload,
} from '@orderly/shared-menu-store';
import {tap} from 'rxjs';


@Injectable()
export class LocalizationEffects {

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
  ) {
  }

  updateActiveLang$ = createEffect(() => this.actions$.pipe(
                                     ofType(activeLanguageChangedAction.type),
                                     tap((x: ActiveLanguageChangedActionPayload) => {

                                       this.translateService.use(x.langCode2.toLowerCase());
                                     }),
                                   ), {dispatch: false}
  );

}

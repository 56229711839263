import {createReducer, on} from '@ngrx/store';
import {FingerprintState} from '@orderly/shared-menu-store';
import {updateClientFingerprintAction} from '@orderly/shared-menu-store';

export const initialState: FingerprintState = {
  data: null,
};

export const fingerprintReducer = createReducer(
  initialState,

  on(updateClientFingerprintAction, (state, {fingerprint}) => {
    return {...state, data: fingerprint};
  }),
);

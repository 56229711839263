import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderableMenuItemModel} from '@orderly/shared-menu-store';

@Component({
             selector: 'orderly-item-details',
             templateUrl: './item-details.component.html',
             styleUrls: ['./item-details.component.scss']
           })
export class ItemDetailsComponent {

  @Input()
  public selectedMenuItem!: OrderableMenuItemModel;

  @Input()
  public isReadonly!: boolean


  @Output()
  public addToCart = new EventEmitter<OrderableMenuItemModel>();
}
